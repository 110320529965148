import React, { useEffect, useMemo, useState } from "react";
import student from "../../assets/images/student.svg";
import medal from "../../assets/images/medal.svg";
import logo from "../../assets/images/logo-dark.svg";
import certificate from "../../assets/images/experience.svg";
import { Button, Form, Input, message } from "antd";
import { classNames } from "../../util/functions";
import { Select } from "antd/es";
import classes from "./DownloadCertificate.module.css";
import { useDispatch, useSelector } from "react-redux";
import { downloadCertificateApi } from "./downloadCertificateApi";
import {
  downloadCertificateActions,
  downloadCertificateSelectors,
} from "./downloadCertificateSlice";
import PreviewCertificateModal from "../../components/Modals/PreviewCertificateModal/PreviewCertificateModal";
import usePrograms from "../../hooks/usePrograms";
import useProgramCenters from "../../hooks/useProgramCenters";

const DownloadCertificate = () => {
  const [step, setStep] = useState(1);

  const [formValues, setFormValues] = useState({});

  const onStep1Finish = (values) => {
    setFormValues(values);
    setStep(2);
  };

  const dispatch = useDispatch();

  const isFetchingCertificate = useSelector(
    downloadCertificateSelectors.isFetchingCertificate
  );
  const fetchingCertificateErrorMessage = useSelector(
    downloadCertificateSelectors.fetchingCertificateErrorMessage
  );
  const fetchingCertificateHasError = useSelector(
    downloadCertificateSelectors.fetchingCertificateHasError
  );
  const fetchingCertificateSuccess = useSelector(
    downloadCertificateSelectors.fetchingCertificateSuccess
  );
  const certificateData = useSelector(
    downloadCertificateSelectors.certificateData
  );

  useEffect(() => {
    if (fetchingCertificateHasError) {
      message.error(fetchingCertificateErrorMessage);
    }
  }, [
    fetchingCertificateErrorMessage,
    fetchingCertificateHasError,
    fetchingCertificateSuccess,
  ]);

  const onStep2Finish = ({ centerId }) => {
    dispatch(
      downloadCertificateApi.generateCertificate({ ...formValues, centerId })
    );
  };

  const onClosePreview = () => {
    dispatch(downloadCertificateActions.resetStatus());
  };

  const { programs, isFetching } = usePrograms();

  const [programId, setProgramId] = useState(null);

  const programName = useMemo(() => {
    if (programId) {
      return programs.find(({ id }) => id === programId).name;
    }

    return "";
  }, [programId, programs]);

  const { centers, isFetchingCenters } = useProgramCenters(programId);

  return (
    <main className={classes.main}>
      <div className={classes.mainBody}>
        <img src={logo} alt="Logo" className={classes.mainBodyLogo} />
        <div className={classes.mainBodyFormWrapper}>
          <div className={classes.timelines}>
            <span
              className={classNames(
                classes.timelineWrapper,
                classes.timelineWrapperActive,
                classes.timelineWrapperBottom
              )}
            >
              <span className={classes.timeline}>1</span>
            </span>
            <span
              className={classNames(
                classes.timelineWrapper,
                step === 2 && classes.timelineWrapperActive,
                classes.timelineWrapperTop
              )}
            >
              <span className={classes.timeline}>2</span>
            </span>
          </div>
          {step === 1 && (
            <div>
              <p className={classes.stepFormLabel}>Step 1</p>
              <p className={classes.stepHeader}>
                Welcome to Tech4dev certificate downloader, kindly fill in your
                details
              </p>
              <Form onFinish={onStep1Finish} initialValues={formValues}>
                <p className={classes.stepFormLabel}>
                  Kindly select the program you participated in to download your
                  certificate
                </p>
                <Form.Item
                  name={"programId"}
                  className={classes.stepFormInput}
                  rules={[
                    {
                      required: true,
                      message: "Program is required",
                    },
                  ]}
                >
                  <Select
                    placeholder={"Program"}
                    loading={isFetching}
                    onChange={setProgramId}
                  >
                    {programs.map(({ id, name }) => (
                      <Select.Option value={id}>{name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <p className={classes.stepFormLabel}>
                  Kindly enter your name the same way you did while registering
                </p>
                <Form.Item
                  name={"firstName"}
                  className={classes.stepFormInput}
                  rules={[
                    {
                      required: true,
                      message: "First Name is required",
                    },
                  ]}
                >
                  <Input placeholder={"First Name"} />
                </Form.Item>
                <Form.Item
                  name={"lastName"}
                  className={classes.stepFormInput}
                  rules={[
                    {
                      required: true,
                      message: "Password is required",
                    },
                  ]}
                >
                  <Input placeholder={"Last Name"} />
                </Form.Item>
                <Button type={"primary"} htmlType={"submit"}>
                  Continue
                </Button>
              </Form>
            </div>
          )}
          {step === 2 && (
            <div>
              <span className={classes.stepLabel}>Step 2</span>
              <p className={classes.stepHeader}>
                Since you participated in {programName}, kindly choose your
                state & center
              </p>
              <p className={classes.stepFormLabel}>
                Kindly select the state in which you attended this program.
              </p>
              <Form onFinish={onStep2Finish}>
                <Form.Item
                  name={"centerId"}
                  rules={[
                    {
                      required: true,
                      message: "Center is required",
                    },
                  ]}
                  className={classes.stepFormInput}
                >
                  <Select placeholder={"Center"} loading={isFetchingCenters}>
                    {centers[programId]?.map(({ id, name }) => (
                      <Select.Option value={id}>{name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Button
                  type={"primary"}
                  htmlType={"submit"}
                  className={classes.previewButton}
                  loading={isFetchingCertificate}
                >
                  Preview Certificate
                </Button>
                <Button
                  type={"link"}
                  className={classes.backButton}
                  onClick={(_) => setStep(1)}
                >
                  Back
                </Button>
              </Form>
            </div>
          )}
        </div>
      </div>
      <aside className={classes.mainSide}>
        <img src={medal} alt="Medal Icon" className={classes.mainSideTopIcon} />
        <img
          src={certificate}
          alt="Certificate Icon"
          className={classes.mainSideBottomIcon}
        />
        <img src={student} alt="Student" className={classes.mainSideImage} />
        <span className={classes.mainSideText}>
          Choose program & Enter details
        </span>
      </aside>
      <PreviewCertificateModal
        data={certificateData}
        onClose={onClosePreview}
        active={fetchingCertificateSuccess}
      />
    </main>
  );
};

export default DownloadCertificate;
