import { Status } from "../../util/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: Status.LOADING,
  programs: [],
  errorMessage: null,
};

const programsSlice = createSlice({
  name: "programs",
  initialState,
  reducers: {
    getProgramsRequest: (state) => {
      state.status = Status.LOADING;
    },
    getProgramsRequestSuccess: (state, action) => {
      state.status = Status.SUCCESS;
      state.programs = action.payload.map((program) => {
        program.total = program.noOfcandidate;
        program.cohorts = program.noOfcenter;
        return program;
      });
    },
    getProgramsRequestError: (state, action) => {
      state.status = Status.FAILURE;
      state.errorMessage = action.payload;
    },
    resetState: (state) => initialState,
  },
});

const {
  getProgramsRequest,
  getProgramsRequestSuccess,
  getProgramsRequestError,
  resetState,
} = programsSlice.actions;

export const programsActions = {
  getProgramsRequest,
  getProgramsRequestSuccess,
  getProgramsRequestError,
  resetState,
};

export const programsSelectors = {
  hasError: (state) => state.programs.status === Status.FAILURE,
  errorMessage: (state) => state.programs.errorMessage,
  isFetching: (state) => state.programs.status === Status.LOADING,
  programs: (state) => state.programs.programs,
};

export const programsReducer = programsSlice.reducer;
