import React from "react";
import classes from "./NavBar.module.css";
import { ReactComponent as Menu } from "../../../assets/icons/menu.svg";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../redux/userSlice";
import avatar from "../../../assets/images/avatar.svg";

const NavBar = ({ onToggleSideBar, onToggleUserDrawer }) => {
  const user = useSelector(userSelectors.userDetails);

  return (
    <div className={classes.navBar}>
      <button className={classes.navBarButton} onClick={onToggleSideBar}>
        <Menu className={classes.navBarButtonIcon} />
      </button>
      {/*  //TODO Replace with real name and image*/}
      <button className={classes.navBarDetails} onClick={onToggleUserDrawer}>
        <img
          src={user.profileUrl || avatar}
          alt={"User Avatar"}
          className={classes.navBarDetailsAvatar}
        />
        <span className={classes.navBarDetailsName}>
          {user.firstName} {user.lastName}
        </span>
      </button>
    </div>
  );
};

export default NavBar;
