import { combineReducers } from "@reduxjs/toolkit";
import { loginReducer } from "../Pages/Auth/Login/loginSlice";
import { signUpReducer } from "../Pages/Auth/SignUp/signUpSlice";
import { forgotPasswordReducer } from "../Pages/Auth/ForgotPassword/forgotPasswordSlice";
import { resetPasswordReducer } from "../Pages/Auth/ResetPassword/resetPasswordSlice";
import { programsReducer } from "../Pages/Programs/programsSlice";
import { addProgramReducer } from "../components/Modals/AddProgramModal/addProgramSlice";
import { singleFormReducer } from "../components/Modals/UploadCandidatesModal/SingleForm/singleFormSlice";
import { batchUploadReducer } from "../components/Modals/UploadCandidatesModal/BatchUpload/batchUploadSlice";
import { comparisonGraphReducer } from "../components/CenterComparisonGraph/comparisonGraphSlice";
import { programReducer } from "../Pages/Programs/ProgramPage/programSlice";
import { addCentersReducer } from "../components/Modals/AddCentersModal/addCentersSlice";
import { userDrawerReducer } from "../components/Layout/UserDrawer/userDrawerSlice";
import { centerPageReducer } from "../Pages/Programs/CenterPage/centerPageSlice";
import { userReducer } from "./userSlice";
import { downloadCertificateReducer } from "../Pages/DownloadCertificate/downloadCertificateSlice";
import { previewCertificateReducer } from "../components/Modals/PreviewCertificateModal/previewCertificateSlice";
import { uploadCertificateReducer } from "../components/Modals/UploadCertificateModal/uploadCertificateSlice";
import { editModalReducer } from "../components/EditModal/editModalSlice";

export const rootReducer = combineReducers({
  login: loginReducer,
  signUp: signUpReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  programs: programsReducer,
  addProgram: addProgramReducer,
  singleForm: singleFormReducer,
  batchUpload: batchUploadReducer,
  comparisonGraph: comparisonGraphReducer,
  program: programReducer,
  addCenters: addCentersReducer,
  userDrawer: userDrawerReducer,
  centerPage: centerPageReducer,
  user: userReducer,
  downloadCertificate: downloadCertificateReducer,
  previewCertificate: previewCertificateReducer,
  uploadCertificate: uploadCertificateReducer,
  editModal: editModalReducer,
});
