import React from "react";
import { Route, Switch } from "react-router-dom";
import Programs from "./Programs";
import ProgramPage from "./ProgramPage/ProgramPage";
import CenterPage from "./CenterPage/CenterPage";

const ProgramsIndex = () => {
  return (
    <Switch>
      <Route path={"/programs"} exact component={Programs} />
      <Route path={"/programs/:programId"} exact component={ProgramPage} />
      <Route path={"/programs/:programId/:centerId"} component={CenterPage} />
    </Switch>
  );
};

export default ProgramsIndex;
