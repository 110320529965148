import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { programsSelectors } from "../../../../Pages/Programs/programsSlice";
import { Button, Form, Input, Select, message } from "antd";
import classes from "./SingleForm.module.css";
import { singleFormActions, singleFormSelectors } from "./singleFormSlice";
import { programsApi } from "../../../../Pages/Programs/programsApi";
import useProgramCenters from "../../../../hooks/useProgramCenters";

const SingleForm = ({ onSubmit, defaultProgram, defaultCenter }) => {
  const programs = useSelector(programsSelectors.programs);

  const initialProgramId = useMemo(() => {
    if (defaultProgram && !defaultCenter) {
      return defaultProgram?.id;
    }

    return null;
  }, [defaultCenter, defaultProgram]);
  const [programId, setProgramId] = useState(initialProgramId);

  const { centers, isFetchingCenters } = useProgramCenters(programId);

  const dispatch = useDispatch();

  const formPrograms = useMemo(() => {
    if (defaultProgram) {
      return [defaultProgram];
    }

    return programs;
  }, [defaultProgram, programs]);

  const formDefaultValues = useMemo(() => {
    const defaultValues = {};
    if (defaultProgram) {
      defaultValues.programId = defaultProgram.id;
    }

    if (defaultCenter) {
      defaultValues.centerId = defaultCenter.id;
    }

    return defaultValues;
  }, [defaultProgram, defaultCenter]);

  const isAddingCandidate = useSelector(singleFormSelectors.isAddingCandidate);
  const addCandidateSuccess = useSelector(
    singleFormSelectors.addCandidateSuccess
  );
  const addCandidateHasError = useSelector(
    singleFormSelectors.addCandidateHasError
  );
  const addCandidateErrorMessage = useSelector(
    singleFormSelectors.addCandidateErrorMessage
  );

  useEffect(() => {
    if (addCandidateSuccess) {
      message.success("Candidate successfully added");
      dispatch(singleFormActions.resetAddCandidate());
      onSubmit();
    }

    if (addCandidateHasError) {
      message.error(addCandidateErrorMessage);
      dispatch(singleFormActions.resetAddCandidate());
    }
  }, [
    addCandidateSuccess,
    addCandidateHasError,
    addCandidateErrorMessage,
    dispatch,
    onSubmit,
  ]);

  const onFormSubmit = (values) => {
    dispatch(programsApi.postCandidate(values));
  };

  const formCenters = useMemo(() => {
    if (defaultCenter) {
      return {
        [defaultProgram.id]: [defaultCenter],
      };
    }

    return centers || {};
  }, [defaultCenter, centers, defaultProgram]);

  return (
    <Form
      initialValues={formDefaultValues}
      className={classes.modalForm}
      onFinish={onFormSubmit}
    >
      <div className={classes.modalFormRow}>
        <Form.Item
          name={"programId"}
          rules={[{ required: true, message: "Program Name is required" }]}
        >
          <Select
            placeholder={"Program Name"}
            disabled={!!defaultProgram?.id}
            onChange={setProgramId}
          >
            {formPrograms.map(({ name, id }) => (
              <Select.Option value={id}>{name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={"centerId"}
          rules={[{ required: true, message: "Center Name is required" }]}
        >
          <Select
            placeholder={"Center Name"}
            loading={isFetchingCenters}
            disabled={!!defaultCenter?.id || isFetchingCenters}
          >
            {defaultCenter ? (
              <Select.Option value={defaultCenter.id}>
                {defaultCenter.name}
              </Select.Option>
            ) : (
              (formCenters[programId] || []).map(({ name, id }) => (
                <Select.Option value={id}>{name}</Select.Option>
              ))
            )}
          </Select>
        </Form.Item>
      </div>
      <div className={classes.modalFormRow}>
        <Form.Item
          name={"firstName"}
          rules={[{ required: true, message: "First Name is required" }]}
        >
          <Input placeholder={"First Name"} />
        </Form.Item>
        <Form.Item
          name={"lastName"}
          rules={[{ required: true, message: "Last Name is required" }]}
        >
          <Input placeholder={"Last Name"} />
        </Form.Item>
      </div>
      <Button
        className={classes.modalFormSubmit}
        type={"primary"}
        loading={isAddingCandidate}
        htmlType={"submit"}
      >
        Upload
      </Button>
    </Form>
  );
};

export default SingleForm;
