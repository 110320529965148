import moment from "moment";
import { PDFDocument, StandardFonts } from "pdf-lib";
import { Modal } from "antd";

const { confirm } = Modal;

/**
 * @see https://github.com/axios/axios/issues/383
 * @see https://github.com/axios/axios/issues/204
 */

export function isNetworkError(error) {
  return (
    error.name === "NetworkError" ||
    error.message.toLowerCase().includes("network") ||
    error.message.toLowerCase().includes("timeout") ||
    !error.status ||
    (!!error?.response && !error?.response.status)
  );
}

export function getErrorMessage(error) {
  if (error.response) {
    return (
      error.response?.data?.message ||
      "Something went wrong, we're not sure what. Our team is working on it."
    );
  } else {
    if (isNetworkError(error))
      return "A network error occured. Kindly check your internet connection";
    else
      return "Something went wrong, we're not sure what. Our team is working on it.";
  }
}

export const convertToFormatted = ({
  amount,
  withSymbol = false,
  stripDecimals = false,
}) => {
  let formatted = new Intl.NumberFormat("en-NG", {
    currency: "NGN",
    style: "currency",
  }).format(amount);
  if (!withSymbol) formatted = formatted.substr(withSymbol ? 0 : 1);
  if (stripDecimals) formatted = formatted.substring(0, formatted.indexOf("."));
  return formatted;
};

export const classNames = (...classes) =>
  classes.filter((className) => className && className.trim(" ")).join(" ");

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";

  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
};

export const transformData = (data) => {
  const colors = ["#50B83C", "#DE3618", "#1866DE", "#F8AE3F"];

  const centers = {};

  data.forEach((day) => {
    const dayFormatted = moment(day.dateofDownload).format("MMM D");

    Object.entries(day.centerStats).forEach(([centerName, noOfDownloads]) => {
      if (!centers[centerName]) {
        centers[centerName] = {
          id: centerName,
          color: colors.pop() || getRandomColor(),
          data: [],
        };
      }

      centers[centerName].data.push({
        x: dayFormatted,
        y: noOfDownloads,
      });
    });
  });

  return Object.values(centers);
};

export const transformTableData = (data) => {
  return data.map((data) => {
    data.name = {
      initials: `${data.firstName[0]}${data.lastName[0]}`,
      fullName: `${data.firstName} ${data.lastName}`,
    };

    data.key = data.id;

    data.download = {
      isDownloaded: data.isDownloaded,
      id: data.id,
    };

    return data;
  });
};

export const addTextToPdf = async ({
  fileArray,
  fontSize,
  text,
  distanceFromTop,
}) => {
  const document = await PDFDocument.load(fileArray);

  const pages = document.getPages();
  const firstPage = pages[0];

  const font = await document.embedFont(StandardFonts.HelveticaBold);
  const { width, height } = firstPage.getSize();

  const textwidth = font.widthOfTextAtSize(text, fontSize);

  firstPage.drawText(text, {
    x: width / 2 - textwidth / 2,
    y: height - distanceFromTop - fontSize,
    size: fontSize,
  });

  const bytes = await document.save();

  return new Blob([bytes], { type: "application/pdf" });
};

export const onDeleteClick = (callbackFunction, name) => {
  confirm({
    title: `Are you sure you want to delete ${name}`,
    onOk: callbackFunction,
    okText: "Yes",
    okType: "danger",
  });
};
