import { useEffect, useState } from "react";
import { makeCachedRequest, makeRequest } from "../../../api/api";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../redux/userSlice";

const AuthCheck = ({ children }) => {
  const [renderChildren, setRenderChildren] = useState(false);

  const token = useSelector(userSelectors.token);
  const isLoggedIn = useSelector(userSelectors.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      makeRequest.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      makeCachedRequest.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
    }

    setRenderChildren(true);
  }, [isLoggedIn, renderChildren, token]);

  if (renderChildren) {
    return children;
  }

  return null;
};

export default AuthCheck;
