import { downloadCertificateActions } from "./downloadCertificateSlice";
import { getErrorMessage } from "../../util/functions";
import { previewCertificateActions } from "../../components/Modals/PreviewCertificateModal/previewCertificateSlice";
import { makeRequest } from "../../api/api";

const postCertificate = (params) => {
  return makeRequest.post("candidates/certificate", params);
};

const getTemplate = (centerId) => {
  return makeRequest(`centers/${centerId}/template`);
};

const generateCertificate = ({
  firstName,
  lastName,
  programId,
  centerId,
  email,
}) => async (dispatch) => {
  dispatch(downloadCertificateActions.getCertificateRequest());
  try {
    const requests = await Promise.all([
      postCertificate({
        firstName,
        lastName,
        programId,
        centerId,
        email,
      }),
      getTemplate(centerId),
    ]);

    const [certificateResponse, templateResponse] = requests;

    dispatch(
      downloadCertificateActions.getCertificateRequestSuccess({
        ...certificateResponse.data.data,
        certificateData: templateResponse.data.data,
      })
    );
  } catch (e) {
    dispatch(
      downloadCertificateActions.getCertificateRequestError(getErrorMessage(e))
    );
  }
};

const markCertificateAsDownloaded = ({ id }) => async (dispatch) => {
  dispatch(previewCertificateActions.markAsReadRequest());
  try {
    await makeRequest.get(`candidates/${id}/mark-as-undownloaded`);
    dispatch(previewCertificateActions.markAsReadRequestSuccess());
  } catch (e) {
    dispatch(
      previewCertificateActions.markAsReadRequestError(getErrorMessage(e))
    );
  }
};

export const downloadCertificateApi = {
  generateCertificate,
  markCertificateAsDownloaded,
};
