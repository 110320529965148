import React from "react";
import classes from "./AuthContainer.module.css";
import logo from "../../assets/images/logo.svg";
import medal from "../../assets/images/medal.svg";
import certificate from "../../assets/images/experience.svg";

const AuthContainer = ({ header, subtext, children, formHeader }) => {
  return (
    <main className={classes.container}>
      <section className={classes.containerSide}>
        <img
          src={medal}
          alt="Medal Icon"
          className={classes.containerSideTopIcon}
        />
        <img
          src={certificate}
          alt="Certificate Icon"
          className={classes.containerSideBottomIcon}
        />
        <img src={logo} alt="Logo" className={classes.containerSideLogo} />
        <h1 className={classes.containerSideHeader}>{header}</h1>
        <h3 className={classes.containerSideText}>{subtext}</h3>
      </section>
      <section className={classes.containerMain}>
        <h3 className={classes.containerMainHeader}>{formHeader}</h3>
        {children}
      </section>
    </main>
  );
};

export default AuthContainer;
