import { editModalActions } from "./editModalSlice";
import { makeRequest } from "../../api/api";
import { getErrorMessage } from "../../util/functions";

const editCenter = (id, newName) => async (dispatch) => {
  dispatch(editModalActions.editRequest());
  try {
    await makeRequest.patch(`centers`, {
      id,
      name: newName,
    });
    console.log("dispatch");
    dispatch(editModalActions.editRequestSuccess());
  } catch (e) {
    dispatch(editModalActions.editRequestFailure(getErrorMessage(e)));
  }
};

const editProgram = (id, newName) => async (dispatch) => {
  dispatch(editModalActions.editRequest());
  try {
    await makeRequest.patch(`programs`, {
      id,
      name: newName,
    });
    console.log("dispatch");
    dispatch(editModalActions.editRequestSuccess());
  } catch (e) {
    dispatch(editModalActions.editRequestFailure(getErrorMessage(e)));
  }
};

export const editModalApi = {
  editCenter,
  editProgram,
};
