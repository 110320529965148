import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  programsActions,
  programsSelectors,
} from "../Pages/Programs/programsSlice";
import { programsApi } from "../Pages/Programs/programsApi";
import { message } from "antd";

const usePrograms = () => {
  const dispatch = useDispatch();

  const programs = useSelector(programsSelectors.programs);
  const isFetching = useSelector(programsSelectors.isFetching);
  const programHasError = useSelector(programsSelectors.hasError);
  const programsErrorMessage = useSelector(programsSelectors.errorMessage);

  useEffect(() => {
    dispatch(programsApi.getPrograms());

    return () => {
      dispatch(programsActions.resetState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (programHasError) {
      message.error(programsErrorMessage);
    }
  }, [programHasError, programsErrorMessage]);

  return {
    programs,
    isFetching,
  };
};

export default usePrograms;
