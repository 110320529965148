import React, { useState } from "react";
import classes from "./UploadCandidatesModal.module.css";
import { Modal } from "antd";
import { classNames } from "../../../util/functions";
import { ReactComponent as AppsIcon } from "../../../assets/icons/apps.svg";
import BatchUpload from "./BatchUpload/BatchUpload";
import SingleForm from "./SingleForm/SingleForm";

const UploadCandidatesModal = ({
  active,
  onClose,
  defaultProgram,
  defaultCenter,
}) => {
  const [multipleUploads, setMultipleUploads] = useState(false);

  return (
    <Modal
      bodyStyle={{
        padding: "0",
      }}
      width={"unset"}
      className={classes.modal}
      visible={active}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <div className={classes.modalBody}>
        <div className={classes.modalSide}>
          <h3 className={classes.modalSideHeader}>Candidate log upload</h3>
          <button
            className={classNames(
              classes.modalSideButton,
              !multipleUploads && classes.modalSideButtonActive
            )}
            onClick={(_) => setMultipleUploads(false)}
          >
            <AppsIcon />
            <span className={classes.modalSideButtonText}>Single Upload</span>
          </button>
          <button
            className={classNames(
              classes.modalSideButton,
              multipleUploads && classes.modalSideButtonActive
            )}
            onClick={(_) => setMultipleUploads(true)}
          >
            <AppsIcon />
            <span className={classes.modalSideButtonText}>Batch Upload</span>
          </button>
        </div>
        <div className={classes.modalMain}>
          <div className={classes.modalMainDownload}>
            <p className={classes.modalMainDownloadText}>
              Click{" "}
              <a
                className={classes.modalMainDownloadTextLink}
                download
                href={"/files/Demo Spreadsheet.csv"}
              >
                here to download
              </a>{" "}
              a sample excel file for log uploads
            </p>
          </div>
          {!multipleUploads ? (
            <SingleForm
              onSubmit={onClose}
              defaultProgram={defaultProgram}
              defaultCenter={defaultCenter}
            />
          ) : (
            <BatchUpload />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default UploadCandidatesModal;
