import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../../util/constants";

const initialState = {
  status: null,
  errorMessage: null,
};

const signUpSlice = createSlice({
  name: "Sign Up",
  initialState,
  reducers: {
    signUpRequestSuccess: (state) => {
      state.status = Status.SUCCESS;
    },
    signUpRequestFailure: (state, action) => {
      state.status = Status.FAILURE;
      state.errorMessage = action.payload;
    },
    reset: (state) => initialState,
  },
});

const {
  signUpRequestSuccess,
  signUpRequestFailure,
  reset,
} = signUpSlice.actions;

export const signUpActions = {
  signUpRequestSuccess,
  signUpRequestFailure,
  reset,
};

export const signUpSelectors = {
  success: (state) => state.signUp.status === Status.SUCCESS,
  failure: (state) => state.signUp.status === Status.FAILURE,
  loading: (state) => state.signUp.status === Status.LOADING,
  errorMessage: (state) => state.signUp.errorMessage,
};

export const signUpReducer = signUpSlice.reducer;
