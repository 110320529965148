import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { singleFormSelectors } from "../components/Modals/UploadCandidatesModal/SingleForm/singleFormSlice";
import { message } from "antd";
import { programsApi } from "../Pages/Programs/programsApi";

const useProgramCenters = (programId) => {
  const dispatch = useDispatch();

  const centers = useSelector(singleFormSelectors.centers);
  const hasError = useSelector(singleFormSelectors.hasError);
  const isFetchingCenters = useSelector(singleFormSelectors.isFetchingCenters);
  const errorMessage = useSelector(singleFormSelectors.errorMessage);

  useEffect(() => {
    if (hasError) {
      message.error(`Error fetching program centers: ${errorMessage}`);
    }
  }, [dispatch, hasError, errorMessage]);

  useEffect(() => {
    if (programId && !centers[programId]) {
      dispatch(programsApi.getProgramCenters(programId));
    }
  }, [programId, dispatch, centers]);

  return {
    centers,
    isFetchingCenters,
  };
};

export default useProgramCenters;
