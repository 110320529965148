import { makeRequest } from "../../../api/api";
import { uploadCertificateActions } from "./uploadCertificateSlice";
import { getErrorMessage } from "../../../util/functions";

const postTemplate = ({ programId, distanceFromTop, file, centerId }) => async (
  dispatch
) => {
  dispatch(uploadCertificateActions.postTemplateRequest());

  const formData = new FormData();

  formData.append("file", file);
  formData.append("programId", programId);
  formData.append("distanceFromTop", distanceFromTop);
  formData.append("centerId", centerId);

  try {
    await makeRequest.post("templates", formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    dispatch(uploadCertificateActions.postTemplateRequestSuccess());
  } catch (e) {
    dispatch(
      uploadCertificateActions.postTemplateRequestError(getErrorMessage(e))
    );
  }
};

export const uploadCertificateApi = {
  postTemplate,
};
