import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../../util/constants";

const initialState = {
  status: null,
  errorMessage: null,
};

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    resetPasswordRequestSuccess: (state) => {
      state.status = Status.SUCCESS;
    },
    resetPasswordRequestFailure: (state, action) => {
      state.status = Status.FAILURE;
      state.errorMessage = action.payload;
    },
    reset: (state) => initialState,
  },
});

const {
  resetPasswordRequestSuccess,
  resetPasswordRequestFailure,
  reset,
} = resetPasswordSlice.actions;

export const resetPasswordActions = {
  resetPasswordRequestSuccess,
  resetPasswordRequestFailure,
  reset,
};

export const resetPasswordSelectors = {
  success: (state) => state.resetPassword.status === Status.SUCCESS,
  failure: (state) => state.resetPassword.status === Status.FAILURE,
  loading: (state) => state.resetPassword.status === Status.LOADING,
  errorMessage: (state) => state.resetPassword.errorMessage,
};

export const resetPasswordReducer = resetPasswordSlice.reducer;
