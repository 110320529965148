import React, { useCallback, useMemo, useState } from "react";
import Container from "../../components/Layout/Container/Container";
import classes from "./Programs.module.css";
import { Button, Input } from "antd";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import ProgramCard, {
  ProgramCardTypes,
} from "../../components/ProgramCard/ProgramCard";
import AddProgramModal from "../../components/Modals/AddProgramModal/AddProgramModal";
import { useDispatch } from "react-redux";
import ContentLoader from "react-content-loader";
import { programsApi } from "./programsApi";
import UploadCandidatesModal from "../../components/Modals/UploadCandidatesModal/UploadCandidatesModal";
import usePrograms from "../../hooks/usePrograms";
import EditModal, {
  EditModalTypes,
} from "../../components/EditModal/EditModal";

const Programs = () => {
  const [addModalActive, setAddModalActive] = useState(false);
  const [uploadModalActive, setUploadModalActive] = useState(false);
  const [editModalState, _setEditModalState] = useState({
    id: null,
    name: null,
    active: false,
  });

  const setEditModalState = (newState) => {
    _setEditModalState({
      ...editModalState,
      ...newState,
    });
  };

  const dispatch = useDispatch();

  const { programs, isFetching } = usePrograms();

  const [search, setSearch] = useState("");

  const validPrograms = useMemo(() => {
    if (search) {
      return programs.filter(({ name }) => new RegExp(search, "i").test(name));
    }

    return programs;
  }, [search, programs]);

  const onAddProgram = () => {
    dispatch(programsApi.getPrograms());
    setAddModalActive(false);
  };

  const onToggleUploadModal = useCallback(() => {
    setUploadModalActive(!uploadModalActive);
  }, [uploadModalActive]);

  const onToggleAddProgramModal = useCallback(() => {
    setAddModalActive(!addModalActive);
  }, [addModalActive]);

  const refreshPrograms = useCallback(() => {
    dispatch(programsApi.getPrograms());
  }, [dispatch]);

  const onCloseEdit = (success) => {
    setEditModalState({ active: false });

    if (success) {
      dispatch(programsApi.getPrograms());
    }
  };

  return (
    <Container>
      <div className={classes.top}>
        <h1 className={classes.topHeader}>Programs</h1>
        <Button
          type={"primary"}
          onClick={onToggleUploadModal}
          disabled={isFetching}
          loading={isFetching}
        >
          Upload Candidates
        </Button>
      </div>
      <Input
        prefix={<Search width={"12px"} />}
        placeholder={"Search programs"}
        className={classes.input}
        value={search}
        onChange={({ target }) => setSearch(target.value)}
      />
      <div className={classes.cardsGrid}>
        {isFetching
          ? Array.from({ length: 4 }, (_, idx) => (
              <ContentLoader width={"100%"} height={"160px"} key={idx}>
                <rect width={"100%"} height={"160px"} />
              </ContentLoader>
            ))
          : validPrograms.map((program) => (
              <ProgramCard
                {...program}
                type={
                  program.isCenter
                    ? ProgramCardTypes.center
                    : ProgramCardTypes.cohort
                }
                key={program.id}
                refreshPrograms={refreshPrograms}
                onEdit={() => {
                  setEditModalState({
                    active: true,
                    id: program.id,
                    name: program.name,
                  });
                }}
              />
            ))}
        <button
          className={classes.newProgramButton}
          onClick={onToggleAddProgramModal}
        >
          <Plus className={classes.newProgramButtonIcon} />
          <span className={classes.newProgramButtonText}>New Program</span>
        </button>
      </div>
      <AddProgramModal
        active={addModalActive}
        onClose={onToggleAddProgramModal}
        onSuccess={onAddProgram}
      />
      <UploadCandidatesModal
        active={uploadModalActive}
        onClose={onToggleUploadModal}
      />
      <EditModal
        visible={editModalState.active}
        onClose={onCloseEdit}
        name={editModalState.name}
        id={editModalState.id}
        type={EditModalTypes.program}
      />
    </Container>
  );
};

export default Programs;
