import React, { useEffect, useMemo } from "react";
import { Button, Form, Modal } from "antd";
import classes from "./AddCentersModal.module.css";
import { Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addCentersActions, addCentersSelectors } from "./addCentersSlice";
import { programPageApi } from "../../../Pages/Programs/ProgramPage/programPageApi";
import { useParams } from "react-router-dom";

const formPlaceholders = {
  center: "Enter name of center/university/country",
  cohort: "Enter name of cohort",
};

const AddCentersModal = ({ program, active, onClose }) => {
  const programType = useMemo(() => (program.isCenter ? "center" : "cohort"), [
    program.isCenter,
  ]);

  const isLoading = useSelector(addCentersSelectors.isLoading);
  const hasError = useSelector(addCentersSelectors.hasError);
  const success = useSelector(addCentersSelectors.success);
  const errorMessage = useSelector(addCentersSelectors.errorMessage);

  const dispatch = useDispatch();

  const { programId } = useParams();

  useEffect(() => {
    if (hasError) {
      message.error(errorMessage);
      dispatch(addCentersActions.reset());
    }

    if (success) {
      message.success("Centers successfully added");
      dispatch(addCentersActions.reset());

      dispatch(programPageApi.getProgramCenters(programId));
      onClose();
    }
  }, [success, errorMessage, hasError, dispatch, onClose, programId]);

  const onSubmit = (values) => {
    const transformedCenters = values.centers.map((center) => ({
      programId: program.id,
      name: center,
    }));
    dispatch(programPageApi.postCentersData(transformedCenters));
  };

  return (
    <Modal
      bodyStyle={{
        padding: "0",
      }}
      width={"unset"}
      className={classes.modal}
      visible={active}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <div className={classes.modalBody}>
        <div className={classes.modalBodyText}>
          <h3 className={classes.modalBodyTextHeader}>New center</h3>
          <p className={classes.modalBodyTextContent}>
            Here you can create a program seamlessly.
          </p>
        </div>
        <Form
          className={classes.modalForm}
          initialValues={{ centers: [""] }}
          onFinish={onSubmit}
        >
          <div className={classes.modalFormProgram}>
            <p className={classes.modalFormProgramText}>{program.name}</p>
          </div>
          <Form.List name={"centers"}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map((field) => (
                    <Form.Item
                      key={field.key}
                      className={classes.modalFormItem}
                    >
                      <Form.Item {...field} noStyle>
                        <Input placeholder={formPlaceholders[programType]} />
                      </Form.Item>
                    </Form.Item>
                  ))}
                  {
                    <button
                      className={classes.modalFormInputAddButton}
                      onClick={(_) => add("")}
                    >
                      Add new {programType}
                    </button>
                  }
                </>
              );
            }}
          </Form.List>
          <Button
            className={classes.modalFormSubmit}
            htmlType={"submit"}
            type={"primary"}
            loading={isLoading}
          >
            Save
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

export default AddCentersModal;
