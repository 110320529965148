import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../../util/constants";

const initialState = {
  status: Status.LOADING,
  errorMessage: null,
  candidates: [],
  centerStatus: Status.LOADING,
  centerErrorMessage: null,
  center: {},
  resendStatus: Status.LOADING,
  resendErrorMessage: null,
};

const centerPageSlice = createSlice({
  name: "centerPage",
  initialState,
  reducers: {
    getCandidatesRequest: (state) => {
      state.status = Status.LOADING;
    },
    getCandidatesRequestSuccess: (state, action) => {
      state.status = Status.SUCCESS;
      state.candidates = action.payload;
    },
    getCandidatesRequestError: (state, action) => {
      state.status = Status.FAILURE;
      state.errorMessage = action.payload;
    },
    getCenterRequest: (state) => {
      state.centerStatus = Status.LOADING;
    },
    getCenterRequestSuccess: (state, action) => {
      state.centerStatus = Status.SUCCESS;
      state.center = action.payload;
    },
    getCenterRequestError: (state, action) => {
      state.centerStatus = Status.FAILURE;
      state.centerErrorMessage = action.payload;
    },
    resendCertificate: (state) => {
      state.resendStatus = Status.LOADING;
    },
    resendCertificateSuccess: (state, action) => {
      state.resendStatus = Status.SUCCESS;
    },
    resendCertificateError: (state, action) => {
      state.resendStatus = Status.FAILURE;
      state.resendErrorMessage = action.payload;
    },
    resetResendStatus: (state) => {
      state.resendStatus = null;
    },
    resetState: (state) => ({
      ...state,
      status: Status.LOADING,
      errorMessage: null,
      candidates: [],
    }),
  },
});

const {
  getCandidatesRequest,
  getCandidatesRequestSuccess,
  getCandidatesRequestError,
  getCenterRequest,
  getCenterRequestSuccess,
  getCenterRequestError,
  resendCertificate,
  resendCertificateSuccess,
  resendCertificateError,
  resetState,
} = centerPageSlice.actions;

export const centerPageActions = {
  getCandidatesRequest,
  getCandidatesRequestSuccess,
  getCandidatesRequestError,
  getCenterRequest,
  getCenterRequestSuccess,
  getCenterRequestError,
  resendCertificate,
  resendCertificateSuccess,
  resendCertificateError,
  resetState,
};

export const centerPageSelectors = {
  isFetching: (state) => state.centerPage.status === Status.LOADING,
  hasError: (state) => state.centerPage.status === Status.FAILURE,
  errorMessage: (state) => state.centerPage.errorMessage,
  candidates: (state) => state.centerPage.candidates,
  centerIsFetching: (state) => state.centerPage.centerStatus === Status.LOADING,
  centerHasError: (state) => state.centerPage.centerStatus === Status.FAILURE,
  centerErrorMessage: (state) => state.centerPage.centerErrorMessage,
  center: (state) => state.centerPage.center,
  resendHasError: (state) => state.centerPage.resendStatus === Status.FAILURE,
  resendSuccess: (state) => state.centerPage.resendStatus === Status.SUCCESS,
  resendErrorMessage: (state) => state.centerPage.resendErrorMessage,
};

export const centerPageReducer = centerPageSlice.reducer;
