import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../../../util/constants";

const initialState = {
  status: null,
  centers: {},
  errorMessage: null,
  addStatus: null,
  addErrorMessage: null,
};

const singleFormSlice = createSlice({
  name: "singleForm",
  initialState,
  reducers: {
    getProgramCenters: (state) => {
      state.status = Status.LOADING;
    },
    getProgramCentersSuccess: (state, action) => {
      state.status = Status.SUCCESS;

      const { id, centers } = action.payload;
      state.centers = {
        ...state.centers,
        [id]: centers,
      };
    },
    getProgramCentersError: (state, action) => {
      state.status = Status.FAILURE;
      state.errorMessage = action.payload;
    },
    resetStatus: (state) => {
      state.status = null;
    },
    addCandidateRequest: (state) => {
      state.addStatus = Status.LOADING;
    },
    addCandidateRequestSuccess: (state) => {
      state.addStatus = Status.SUCCESS;
    },
    addCandidateRequestError: (state, action) => {
      state.addStatus = Status.FAILURE;
      state.addErrorMessage = action.payload;
    },
    resetAddCandidate: (state) => {
      state.addStatus = null;
      state.addErrorMessage = null;
    },
  },
});

const {
  getProgramCenters,
  getProgramCentersSuccess,
  getProgramCentersError,
  resetStatus,
  addCandidateRequest,
  addCandidateRequestSuccess,
  addCandidateRequestError,
  resetAddCandidate,
} = singleFormSlice.actions;

export const singleFormActions = {
  getProgramCenters,
  getProgramCentersSuccess,
  getProgramCentersError,
  resetStatus,
  addCandidateRequest,
  addCandidateRequestSuccess,
  addCandidateRequestError,
  resetAddCandidate,
};

export const singleFormSelectors = {
  centers: (state) => state.singleForm.centers,
  hasError: (state) => state.singleForm.status === Status.FAILURE,
  isFetchingCenters: (state) => state.singleForm.status === Status.LOADING,
  errorMessage: (state) => state.singleForm.errorMessage,
  isAddingCandidate: (state) => state.singleForm.addStatus === Status.LOADING,
  addCandidateSuccess: (state) => state.singleForm.addStatus === Status.SUCCESS,
  addCandidateHasError: (state) =>
    state.singleForm.addStatus === Status.FAILURE,
  addCandidateErrorMessage: (state) => state.singleForm.addErrorMessage,
};

export const singleFormReducer = singleFormSlice.reducer;
