import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../../util/constants";

const initialState = {
  status: null,
  errorMessage: null,
};

const userDrawerSlice = createSlice({
  name: "userDrawer",
  initialState,
  reducers: {
    userDrawerRequest: (state) => {
      state.status = Status.LOADING;
    },
    userDrawerRequestSuccess: (state) => {
      state.status = Status.SUCCESS;
    },
    userDrawerRequestError: (state, action) => {
      state.status = Status.FAILURE;
      state.errorMessage = action.payload;
    },
    reset: (state) => initialState,
  },
});

const {
  userDrawerRequest,
  userDrawerRequestSuccess,
  userDrawerRequestError,
  reset,
} = userDrawerSlice.actions;

export const userDrawerActions = {
  userDrawerRequest,
  userDrawerRequestSuccess,
  userDrawerRequestError,
  reset,
};

export const userDrawerSelectors = {
  isLoading: (state) => state.userDrawer.status === Status.LOADING,
  hasError: (state) => state.userDrawer.status === Status.FAILURE,
  success: (state) => state.userDrawer.status === Status.SUCCESS,
  errorMessage: (state) => state.userDrawer.errorMessage,
};

export const userDrawerReducer = userDrawerSlice.reducer;
