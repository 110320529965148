import { loginActions } from "./Login/loginSlice";
import { getErrorMessage } from "../../util/functions";
import { signUpActions } from "./SignUp/signUpSlice";
import { forgotPasswordActions } from "./ForgotPassword/forgotPasswordSlice";
import { resetPasswordActions } from "./ResetPassword/resetPasswordSlice";
import { userActions } from "../../redux/userSlice";
import { makeCachedRequest, makeRequest } from "../../api/api";

const login = ({ email, password }) => {
  return async (dispatch) => {
    try {
      const response = await makeRequest.post("/auth/sign-in", {
        email,
        password,
      });

      const { data } = response.data;

      const { token, ...userDetails } = data;

      makeRequest.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token.token}`;
      makeCachedRequest.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token.token}`;

      dispatch(userActions.logIn({ token: token.token, userDetails }));
      dispatch(loginActions.loginRequestSuccess());
    } catch (e) {
      dispatch(loginActions.loginRequestFailure(getErrorMessage(e)));
    }
  };
};

const signUp = ({ email, password, firstName, lastName }) => async (
  dispatch
) => {
  try {
    await makeRequest.post("/auth/sign-up", {
      email,
      password,
      firstName,
      lastName,
    });

    dispatch(signUpActions.signUpRequestSuccess());
  } catch (e) {
    dispatch(signUpActions.signUpRequestFailure(getErrorMessage(e)));
  }
};

const forgotPassword = (email) => async (dispatch) => {
  try {
    await makeRequest.post("auth/reset-password-code", {
      email,
    });

    dispatch(forgotPasswordActions.forgotPasswordRequestSuccess());
  } catch (e) {
    dispatch(
      forgotPasswordActions.forgotPasswordRequestFailure(getErrorMessage(e))
    );
  }
};

const resetPassword = ({ email, code, newPassword }) => async (dispatch) => {
  try {
    await makeRequest.post("/auth/reset-password", {
      email,
      code,
      newPassword,
    });

    dispatch(resetPasswordActions.resetPasswordRequestSuccess());
  } catch (e) {
    dispatch(
      resetPasswordActions.resetPasswordRequestFailure(getErrorMessage(e))
    );
  }
};

export const authApi = {
  login,
  signUp,
  forgotPassword,
  resetPassword,
};
