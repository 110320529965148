import React from "react";
import { Card, message } from "antd";
import { ReactComponent as Cap } from "../../assets/icons/mortarboard.svg";
import classes from "./ProgramCard.module.css";
import { onDeleteClick } from "../../util/functions";
import CardActions from "../CardActions/CardActions";
import { useHistory } from "react-router-dom";
import { programsApi } from "../../Pages/Programs/programsApi";

export const ProgramCardTypes = {
  cohort: "cohorts",
  center: "centers",
};

const ProgramCard = ({
  id,
  name,
  cohorts,
  total,
  type,
  refreshPrograms,
  onEdit,
}) => {
  const history = useHistory();

  const onDeleteCenter = async () => {
    try {
      await programsApi.deleteProgram(id);
      message.success("Successfully deleted program");
      refreshPrograms();
    } catch (error) {
      message.error(error.message);
    }
  };

  const onCardClick = () => {
    history.push(`/programs/${id}`);
  };

  const renderCardTitle = () => (
    <div className={classes.cardTitle}>
      <h5 className={classes.cardTitleHeader}>{name}</h5>
      <CardActions
        onDelete={() => onDeleteClick(onDeleteCenter, name)}
        onEdit={onEdit}
      />
    </div>
  );

  return (
    <Card
      title={renderCardTitle()}
      headStyle={{ borderBottom: "none" }}
      onClick={onCardClick}
      className={classes.card}
    >
      <Cap className={classes.cardIcon} />
      <div className={classes.cardNumbers}>
        <span className={classes.cardNumbersTotal}>{total}</span>
        <span className={classes.cardNumbersCohorts}>
          {cohorts} {type}
        </span>
      </div>
    </Card>
  );
};

export default ProgramCard;
