import React, { useEffect, useState } from "react";
import classes from "./AddProgramModal.module.css";
import { Button, Modal } from "antd";
import Form from "antd/es/form";
import { Input } from "antd/es";
import { Radio, message } from "antd";
import { classNames } from "../../../util/functions";
import { programsApi } from "../../../Pages/Programs/programsApi";
import { useDispatch, useSelector } from "react-redux";
import { addProgramActions, addProgramSelectors } from "./addProgramSlice";

const formPlaceholders = {
  center: "Enter name of center/university/country",
  cohort: "Enter name of cohort",
};

const AddProgramModal = ({ active, onSuccess, onClose }) => {
  const [programType, setProgramType] = useState("center");

  const dispatch = useDispatch();

  const onSubmit = (values) => {
    values.isCenter = programType === "center";
    dispatch(programsApi.addProgram(values));
  };

  const isLoading = useSelector(addProgramSelectors.isLoading);
  const hasError = useSelector(addProgramSelectors.hasError);
  const success = useSelector(addProgramSelectors.success);
  const errorMessage = useSelector(addProgramSelectors.errorMessage);

  const [form] = Form.useForm();

  useEffect(() => {
    if (success) {
      dispatch(addProgramActions.reset());
      message.success("Program successfully added");
      onSuccess();
      form.resetFields();
    }
  }, [success, dispatch, onSuccess, form]);

  useEffect(() => {
    if (hasError) {
      message.error(errorMessage);
    }
  }, [hasError, errorMessage]);

  return (
    <Modal
      visible={active}
      footer={null}
      width={"unset"}
      className={classes.modal}
      bodyStyle={{
        backgroundColor: "#F4F6F8",
        paddingTop: "0",
        paddingBottom: "0",
      }}
      onCancel={onClose}
    >
      <div className={classes.modalBody}>
        <div className={classes.modalBodyText}>
          <h4 className={classes.modalBodyTextHeader}>Create program</h4>
          <p className={classes.modalBodyTextContent}>
            Here you can create a program seamlessly.
          </p>
        </div>
        <Form
          className={classes.modalForm}
          initialValues={{
            name: "",
            centerOrCohort: [""],
          }}
          onFinish={onSubmit}
          form={form}
        >
          <Form.Item name={"name"}>
            <Input placeholder={"Program name"} />
          </Form.Item>
          <div className={classes.modalFormRadioButtons}>
            <button
              className={classNames(
                classes.modalFormRadioButton,
                programType === "center" && classes.modalFormRadioButtonActive
              )}
              onClick={(_) => setProgramType("center")}
              type={"button"}
            >
              <Radio checked={programType === "center"} />
              <span className={classes.modalFormRadioButtonText}>Center</span>
            </button>
            <button
              className={classNames(
                classes.modalFormRadioButton,
                programType === "cohort" && classes.modalFormRadioButtonActive
              )}
              onClick={(_) => setProgramType("cohort")}
              type={"button"}
            >
              <Radio checked={programType === "cohort"} />
              <span className={classes.modalFormRadioButtonText}>Cohort</span>
            </button>
          </div>
          <Form.List name={"centerOrCohort"}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map((field) => (
                    <Form.Item
                      key={field.key}
                      className={classes.modalFormItem}
                    >
                      <Form.Item {...field} noStyle>
                        <Input placeholder={formPlaceholders[programType]} />
                      </Form.Item>
                    </Form.Item>
                  ))}
                  {
                    <button
                      className={classes.modalFormInputAddButton}
                      onClick={(_) => add("")}
                      type={"button"}
                    >
                      Add new {programType}
                    </button>
                  }
                </>
              );
            }}
          </Form.List>
          <Button
            className={classes.modalFormSubmit}
            htmlType={"submit"}
            type={"primary"}
            loading={isLoading}
          >
            Save
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

export default AddProgramModal;
