import React, { useCallback, useEffect, useMemo, useState } from "react";
import Container from "../../../components/Layout/Container/Container";
import classes from "./ProgramPage.module.css";
import backIcon from "../../../assets/images/back.svg";
import { Link, useParams } from "react-router-dom";
import { Button, Card, Input } from "antd";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import CenterCard from "../../../components/CenterCard/CenterCard";
import { ReactComponent as Plus } from "../../../assets/icons/plus.svg";
import CenterComparisonGraph from "../../../components/CenterComparisonGraph/CenterComparisonGraph";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ContentLoader from "react-content-loader";
import { programPageSelectors } from "./programSlice";
import { programPageApi } from "./programPageApi";
import UploadCandidatesModal from "../../../components/Modals/UploadCandidatesModal/UploadCandidatesModal";
import AddCentersModal from "../../../components/Modals/AddCentersModal/AddCentersModal";
import useProgramData from "../../../hooks/useProgramData";
import UploadCertificateModal from "../../../components/Modals/UploadCertificateModal/UploadCertificateModal";
import EditModal, {
  EditModalTypes,
} from "../../../components/EditModal/EditModal";

const ProgramPage = () => {
  const [search, setSearch] = useState("");
  const [uploadModalActive, setUploadModalActive] = useState(false);
  const [addCenterModalActive, setAddCenterModalActive] = useState(false);
  const [uploadCertificateActive, setUploadCertificateActive] = useState(false);
  const [editModalState, _setEditModalState] = useState({
    id: null,
    name: null,
    active: false,
  });

  const setEditModalState = (newState) => {
    _setEditModalState({
      ...editModalState,
      ...newState,
    });
  };

  //This variable is just to make the graph refresh on changes (like editing or deleting a center)
  const [refreshGraph, setRefreshGraph] = useState(false);
  const { programId } = useParams();

  const isFetching = useSelector(programPageSelectors.isFetching);
  const hasError = useSelector(programPageSelectors.hasError);
  const errorMessage = useSelector(programPageSelectors.errorMessage);
  const centers = useSelector(programPageSelectors.centers);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(programPageApi.getProgramCenters(programId));
  }, [programId, dispatch]);

  useEffect(() => {
    if (hasError) {
      message.error(`Error fetching program centers: ${errorMessage}`);
    }
  }, [hasError, errorMessage]);

  const validCenters = useMemo(() => {
    if (search) {
      return centers.filter(({ name }) => new RegExp(search, "i").test(name));
    }

    return centers;
  }, [centers, search]);

  const refreshCenters = () => {
    dispatch(programPageApi.getProgramCenters(programId));
    setRefreshGraph(true);
  };

  const { program, programIsFetching } = useProgramData();

  const onToggleUploadModal = useCallback(() => {
    setUploadModalActive(!uploadModalActive);
  }, [uploadModalActive]);

  const onToggleAddCenterModal = useCallback(() => {
    setAddCenterModalActive(!addCenterModalActive);
  }, [addCenterModalActive]);

  const onToggleUploadCertificate = useCallback(() => {
    setUploadCertificateActive(!uploadCertificateActive);
  }, [uploadCertificateActive]);

  const onCloseEdit = (success) => {
    setEditModalState({ active: false });

    if (success) {
      refreshCenters();
    }
  };

  const renderTop = () => {
    return (
      <div className={classes.top}>
        <div className={classes.topText}>
          <div className={classes.topBreadCrumb}>
            <img
              src={backIcon}
              alt="Back"
              className={classes.topBreadCrumbIcon}
            />
            <Link className={classes.topBreadCrumbText} to={"/programs"}>
              Programs
            </Link>
          </div>
          {programIsFetching ? (
            <ContentLoader style={{ marginTop: "5px", height: "44px" }}>
              <rect width={"100px"} height={"44px"} />
            </ContentLoader>
          ) : (
            <h3 className={classes.topBreadCrumbHeader}>{program.name}</h3>
          )}
        </div>
        <div className={classes.topButtons}>
          <Button
            type={"primary"}
            onClick={onToggleUploadModal}
            disabled={isFetching}
            loading={isFetching}
          >
            Upload Candidates
          </Button>
          <Button
            onClick={onToggleUploadCertificate}
            className={classes.topButton}
          >
            Upload Certificate Template
          </Button>
        </div>
      </div>
    );
  };

  const renderGraph = () => {
    return (
      <Card className={classes.graphCard}>
        <h4 className={classes.graphHeader}>Comparison By Center</h4>
        <CenterComparisonGraph
          refreshGraph={refreshGraph}
          resetRefresh={() => setRefreshGraph(false)}
        />
      </Card>
    );
  };

  const renderCards = () => {
    return (
      <div className={classes.listGrid}>
        {isFetching
          ? Array.from({ length: 4 }, (_, idx) => (
              <ContentLoader width={"100%"} height={"160px"} key={idx}>
                <rect width={"100%"} height={"160px"} />
              </ContentLoader>
            ))
          : validCenters.map(
              ({ id, name, noOfcandidate, percentDownloaded }) => (
                <CenterCard
                  id={id}
                  name={name}
                  percentDownloaded={percentDownloaded}
                  total={noOfcandidate}
                  programId={programId}
                  refreshCenters={refreshCenters}
                  onEdit={() => {
                    setEditModalState({
                      active: true,
                      id,
                      name,
                    });
                  }}
                />
              )
            )}
        <button
          className={classes.newCenterButton}
          onClick={onToggleAddCenterModal}
        >
          <Plus className={classes.newCenterButtonIcon} />
          <span className={classes.newCenterButtonText}>New Center</span>
        </button>
      </div>
    );
  };

  return (
    <Container>
      {renderTop()}
      {renderGraph()}
      <p className={classes.listHeader}>List of Centers</p>
      <Input
        prefix={<Search width={"12px"} />}
        placeholder={"Search programs"}
        className={classes.listInput}
        value={search}
        onChange={({ target }) => setSearch(target.value)}
      />
      {renderCards()}
      <UploadCandidatesModal
        active={uploadModalActive}
        onClose={onToggleUploadModal}
        defaultProgram={program}
      />
      <AddCentersModal
        program={program}
        active={addCenterModalActive}
        onClose={onToggleAddCenterModal}
      />
      <UploadCertificateModal
        programId={programId}
        visible={uploadCertificateActive}
        onClose={onToggleUploadCertificate}
      />
      <EditModal
        visible={editModalState.active}
        onClose={onCloseEdit}
        name={editModalState.name}
        id={editModalState.id}
        type={EditModalTypes.center}
      />
    </Container>
  );
};

export default ProgramPage;
