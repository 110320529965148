import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../../util/constants";

const initialState = {
  status: null,
  errorMessage: null,
};

const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    forgotPasswordRequestSuccess: (state) => {
      state.status = Status.SUCCESS;
    },
    forgotPasswordRequestFailure: (state, action) => {
      state.status = Status.FAILURE;
      state.errorMessage = action.payload;
    },
    reset: (state) => initialState,
  },
});

const {
  forgotPasswordRequestSuccess,
  forgotPasswordRequestFailure,
  reset,
} = forgotPasswordSlice.actions;

export const forgotPasswordActions = {
  forgotPasswordRequestSuccess,
  forgotPasswordRequestFailure,
  reset,
};

export const forgotPasswordSelectors = {
  success: (state) => state.forgotPassword.status === Status.SUCCESS,
  failure: (state) => state.forgotPassword.status === Status.FAILURE,
  loading: (state) => state.forgotPassword.status === Status.LOADING,
  errorMessage: (state) => state.forgotPassword.errorMessage,
};

export const forgotPasswordReducer = forgotPasswordSlice.reducer;
