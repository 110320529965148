import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../../../util/constants";

const initialState = {
  status: null,
  errorRows: [],
  errors: [],
  requestError: null,
};

const batchUploadSlice = createSlice({
  name: "batchUpload",
  initialState,
  reducers: {
    uploadCandidatesRequest: (state) => {
      state.status = Status.LOADING;
    },
    uploadCandidatesRequestSuccess: (state) => {
      state.status = Status.SUCCESS;
    },
    uploadCandidatesRequestError: (state, action) => {
      const { errorRows, errors } = action.payload;
      state.errorRows = errorRows;
      state.errors = errors;
      state.status = Status.FAILURE;
    },
    uploadCandidatesRequestNetworkError: (state, action) => {
      state.requestError = action.payload;
    },
    reset: (state) => initialState,
  },
});

const {
  uploadCandidatesRequest,
  uploadCandidatesRequestSuccess,
  uploadCandidatesRequestError,
  reset,
  uploadCandidatesRequestNetworkError,
} = batchUploadSlice.actions;

export const batchUploadActions = {
  uploadCandidatesRequest,
  uploadCandidatesRequestSuccess,
  uploadCandidatesRequestError,
  reset,
  uploadCandidatesRequestNetworkError,
};

export const batchUploadSelectors = {
  uploading: (state) => state.batchUpload.status === Status.LOADING,
  hasError: (state) => state.batchUpload.status === Status.FAILURE,
  uploaded: (state) => state.batchUpload.status === Status.SUCCESS,
  errorRows: (state) => state.batchUpload.errorRows,
  errors: (state) => state.batchUpload.errors,
  requestError: (state) => state.batchUpload.requestError,
};

export const batchUploadReducer = batchUploadSlice.reducer;
