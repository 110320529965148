import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../../util/constants";

const initialState = {
  status: null,
  errorMessage: null,
};

const addCentersSlice = createSlice({
  name: "addCenters",
  initialState,
  reducers: {
    addCentersRequest: (state) => {
      state.status = Status.LOADING;
    },
    addCentersRequestSuccess: (state) => {
      state.status = Status.SUCCESS;
    },
    addCentersRequestError: (state, action) => {
      state.status = Status.FAILURE;
      state.errorMessage = action.payload;
    },
    reset: (state) => initialState,
  },
});

const {
  addCentersRequest,
  addCentersRequestSuccess,
  addCentersRequestError,
  reset,
} = addCentersSlice.actions;

export const addCentersActions = {
  addCentersRequest,
  addCentersRequestSuccess,
  addCentersRequestError,
  reset,
};

export const addCentersSelectors = {
  isLoading: (state) => state.addCenters.status === Status.LOADING,
  hasError: (state) => state.addCenters.status === Status.FAILURE,
  success: (state) => state.addCenters.status === Status.SUCCESS,
  errorMessage: (state) => state.addCenters.errorMessage,
};

export const addCentersReducer = addCentersSlice.reducer;
