import React, { useCallback, useEffect, useMemo, useState } from "react";
import classes from "./CenterPage.module.css";
import backIcon from "../../../assets/images/back.svg";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Breadcrumb,
  Card,
  Input,
  Select,
  Divider,
  Tag,
  Table,
} from "antd";
import Container from "../../../components/Layout/Container/Container";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import { useDispatch, useSelector } from "react-redux";
import { centerPageActions, centerPageSelectors } from "./centerPageSlice";
import { message } from "antd";
import { centerPageApi } from "./centerPageApi";
import ContentLoader from "react-content-loader";
import useProgramData from "../../../hooks/useProgramData";
import UploadCandidatesModal from "../../../components/Modals/UploadCandidatesModal/UploadCandidatesModal";

const CenterPage = () => {
  const { resendCertificate } = useResendCertificate();

  const tableColumns = useMemo(
    () => [
      {
        title: "Name",
        key: "name",
        dataIndex: "name",
        render: (name) => (
          <div className={classes.tableName}>
            <span className={classes.tableNameInitials}>{name.initials}</span>
            <span className={classes.tableNameText}>{name.fullName}</span>
          </div>
        ),
      },
      {
        title: "Download Status",
        key: "download",
        dataIndex: "download",
        render: ({ isDownloaded, id }) => {
          let tag;
          if (isDownloaded) {
            tag = (
              <Tag color={"#B4E0FA"} className={classes.tableDownloadStatus}>
                Downloaded
              </Tag>
            );
          } else {
            tag = (
              <Tag color={"#DFE3E8"} className={classes.tableDownloadStatus}>
                Not Downloaded
              </Tag>
            );
          }

          return (
            <div>
              {tag}
              <Button
                className={classes.resendCertificate}
                onClick={(_) => resendCertificate(id)}
              >
                Resend Certificate
              </Button>
            </div>
          );
        },
      },
    ],
    [resendCertificate]
  );

  const dispatch = useDispatch();
  const { programId, centerId } = useParams();

  const { program, programIsFetching } = useProgramData();

  const { centerIsFetching, center } = useCenterData();

  const [search, setSearch] = useState("");
  const [downloadStatus, setDownloadStatus] = useState(null);
  const [uploadModalActive, setUploadModalActive] = useState(false);

  const isFetching = useSelector(centerPageSelectors.isFetching);
  const hasError = useSelector(centerPageSelectors.hasError);
  const errorMessage = useSelector(centerPageSelectors.errorMessage);
  const candidates = useSelector(centerPageSelectors.candidates);

  useEffect(() => {
    if (hasError) {
      message.error(errorMessage);
    }
  }, [hasError, errorMessage]);

  const getCandidates = useCallback(() => {
    dispatch(centerPageApi.getCandidates({ centerId, downloadStatus, search }));
  }, [dispatch, centerId, downloadStatus, search]);

  useEffect(() => {
    getCandidates();

    return () => dispatch(centerPageActions.resetState());
  }, [dispatch, getCandidates]);

  const onToggleUploadModal = useCallback(() => {
    setUploadModalActive(!uploadModalActive);
  }, [uploadModalActive]);

  return (
    <Container>
      <div className={classes.top}>
        <div className={classes.topText}>
          <div className={classes.topBreadCrumb}>
            <img
              src={backIcon}
              alt="Back"
              className={classes.topBreadCrumbIcon}
            />
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link className={classes.topBreadCrumbText} to={"/programs"}>
                  Programs
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {programIsFetching ? (
                  <ContentLoader style={{ marginTop: "5px", height: "14px" }}>
                    <rect width={"50px"} height={"14px"} />
                  </ContentLoader>
                ) : (
                  <Link
                    className={classes.topBreadCrumbText}
                    to={`/programs/${programId}`}
                  >
                    {program.name}
                  </Link>
                )}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          {centerIsFetching ? (
            <ContentLoader style={{ marginTop: "5px", height: "44px" }}>
              <rect width={"100px"} height={"44px"} />
            </ContentLoader>
          ) : (
            <h3 className={classes.topBreadCrumbHeader}>{center.name}</h3>
          )}
        </div>
        <Button
          type={"primary"}
          onClick={onToggleUploadModal}
          disabled={isFetching}
          loading={isFetching}
        >
          Upload Candidates
        </Button>
      </div>
      <Card className={classes.card}>
        <div className={classes.cardFilters}>
          <Input
            allowClear
            prefix={<Search width={"12px"} />}
            placeholder={"Filter Candidates"}
            value={search}
            onChange={({ target }) => setSearch(target.value)}
          />
          <Select
            placeholder={"Download Status"}
            value={downloadStatus}
            onChange={setDownloadStatus}
          >
            <Select.Option value={null}>Download Status: All</Select.Option>
            <Select.Option value={true}>
              Download Status: Downloaded
            </Select.Option>
            <Select.Option value={false}>
              Download Status: Not downloaded
            </Select.Option>
          </Select>
        </div>
        <p className={classes.cardCount}>Showing {candidates.length} users</p>
        <Divider />
        <Table
          columns={tableColumns}
          dataSource={candidates}
          size="middle"
          pagination={false}
          rowClassName={classes.tableRow}
          loading={isFetching}
          scroll={{ x: "max-content" }}
        />
      </Card>
      <UploadCandidatesModal
        active={uploadModalActive}
        onClose={onToggleUploadModal}
        defaultProgram={program}
        defaultCenter={center}
      />
    </Container>
  );
};

const useResendCertificate = () => {
  const resendHasError = useSelector(centerPageSelectors.resendHasError);
  const resendSuccess = useSelector(centerPageSelectors.resendSuccess);
  const resendErrorMessage = useSelector(
    centerPageSelectors.resendErrorMessage
  );

  useEffect(() => {
    if (resendSuccess) {
      message.destroy();
      message.success("Certificate successfully reactivated for download");
    }

    if (resendHasError) {
      message.destroy();
      message.error(`Error reactivating certificate: ${resendErrorMessage}`);
    }
  }, [resendSuccess, resendErrorMessage, resendHasError]);

  const dispatch = useDispatch();

  const resendCertificate = (candidateId) => {
    message.loading("Reactivating certificate download status");
    dispatch(centerPageApi.resendCertificate(candidateId));
  };

  return {
    resendCertificate,
  };
};

const useCenterData = () => {
  const dispatch = useDispatch();
  const { centerId } = useParams();

  const centerIsFetching = useSelector(centerPageSelectors.centerIsFetching);
  const centerHasError = useSelector(centerPageSelectors.centerHasError);
  const centerErrorMessage = useSelector(
    centerPageSelectors.centerErrorMessage
  );
  const center = useSelector(centerPageSelectors.center);

  useEffect(() => {
    dispatch(centerPageApi.getCenterData(centerId));
  }, [dispatch, centerId]);

  useEffect(() => {
    if (centerHasError) {
      message.error(`Error fetching center data: ${centerErrorMessage}`);
    }
  }, [dispatch, centerErrorMessage, centerHasError]);

  return {
    centerIsFetching,
    center,
  };
};

export default CenterPage;
