import React, { useEffect } from "react";
import { ResponsiveLine } from "@nivo/line";
import { message } from "antd";
import classes from "./CenterComparisonGraph.module.css";
import { useDispatch, useSelector } from "react-redux";
import { comparisonGraphSelectors } from "./comparisonGraphSlice";
import ContentLoader from "react-content-loader";
import { useParams } from "react-router-dom";
import { programPageApi } from "../../Pages/Programs/ProgramPage/programPageApi";

const getTooltip = (data) => {
  const { points } = data.slice;

  return (
    <div className={classes.tooltip}>
      <p className={classes.tooltipHeader}>{points[0]?.data.x}</p>
      <div className={classes.tooltipItems}>
        {points.map(({ data, color, serieId }) => (
          <div className={classes.tooltipItem}>
            <span
              className={classes.tooltipItemColor}
              style={{ backgroundColor: color }}
            />
            <div className={classes.tooltipItemText}>
              <span className={classes.tooltipItemTextId}>{serieId}:</span>
              <span>{data.y}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const CenterComparisonGraph = ({ refreshGraph, resetRefresh }) => {
  const isFetching = useSelector(comparisonGraphSelectors.isFetching);
  const hasError = useSelector(comparisonGraphSelectors.hasError);
  const errorMessage = useSelector(comparisonGraphSelectors.errorMessage);
  const data = useSelector(comparisonGraphSelectors.data);

  const dispatch = useDispatch();

  const { programId } = useParams();

  useEffect(() => {
    if (refreshGraph) {
      dispatch(programPageApi.getProgramGraph(programId));
      resetRefresh();
    }
  }, [dispatch, programId, refreshGraph, resetRefresh]);

  useEffect(() => {
    dispatch(programPageApi.getProgramGraph(programId));
  }, [programId, dispatch]);

  useEffect(() => {
    if (hasError) {
      message.error(`Error fetching graph data: ${errorMessage}`);
    }
  }, [hasError, errorMessage]);

  return (
    <div style={{ height: "400px", width: "100%" }}>
      {isFetching ? (
        <ContentLoader style={{ height: "400px", width: "100%" }}>
          <rect height={"400px"} width={"100%"} />
        </ContentLoader>
      ) : (
        <ResponsiveLine
          data={data}
          margin={{ top: 30, right: 30, bottom: 60, left: 30 }}
          xScale={{ type: "point" }}
          yScale={{ type: "linear", min: "auto", max: "auto", stacked: false }}
          lineWidth={1}
          enablePoints={false}
          enableGridX={false}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          enableSlices={"x"}
          sliceTooltip={getTooltip}
          enableCrosshair={false}
          legends={[
            {
              anchor: "bottom-left",
              direction: "row",
              translateX: 0,
              translateY: 60,
              itemDirection: "left-to-right",
              itemHeight: 20,
              itemWidth: 100,
              itemsSpacing: 30,
              symbolSize: 10,
            },
          ]}
        />
      )}
    </div>
  );
};

export default CenterComparisonGraph;
