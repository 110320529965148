import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Divider, Drawer, Form, Input, message } from "antd";
import classes from "./UserDrawer.module.css";
import { classNames } from "../../../util/functions";
import { useDispatch, useSelector } from "react-redux";
import { userDrawerApi } from "./userDrawerApi";
import { userDrawerActions, userDrawerSelectors } from "./userDrawerSlice";
import { userSelectors } from "../../../redux/userSlice";
import avatar from "../../../assets/images/avatar-dark.svg";

const oldPasswordValidator = ({ getFieldValue }) => ({
  validator(rule, value) {
    if (!value && getFieldValue("newPassword")) {
      return Promise.reject(
        "Kindly input your new password to complete password change."
      );
    }

    return Promise.resolve();
  },
});

const newPasswordValidator = ({ getFieldValue }) => ({
  validator(rule, value) {
    if (!value && getFieldValue("oldPassword")) {
      return Promise.reject(
        "Kindly input your old password to complete password change."
      );
    }

    return Promise.resolve();
  },
});

const UserDrawer = ({ active, onClose }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(userDrawerSelectors.isLoading);
  const hasError = useSelector(userDrawerSelectors.hasError);
  const success = useSelector(userDrawerSelectors.success);
  const errorMessage = useSelector(userDrawerSelectors.errorMessage);

  const user = useSelector(userSelectors.userDetails);

  const [filePreviewUrl, setFilePreviewUrl] = useState("");
  const fileRef = useRef();
  const [form] = Form.useForm();

  const _onClose = useCallback(() => {
    form.resetFields();
    fileRef.current = null;

    if (filePreviewUrl.current) {
      URL.revokeObjectURL(filePreviewUrl.current);
    }

    setFilePreviewUrl("");

    onClose();
  }, [filePreviewUrl, form, onClose]);

  useEffect(() => {
    if (success) {
      message.success("Updated user profile");
      dispatch(userDrawerActions.reset());
      _onClose();
    }

    if (hasError) {
      message.error(errorMessage);
      dispatch(userDrawerActions.reset());
    }

    return () => {
      if (filePreviewUrl) {
        URL.revokeObjectURL(filePreviewUrl);
        dispatch(userDrawerActions.reset());
      }
    };
  }, [
    success,
    errorMessage,
    hasError,
    dispatch,
    onClose,
    filePreviewUrl,
    _onClose,
  ]);

  const onSubmit = (values) => {
    dispatch(userDrawerApi.updateProfile({ ...values, file: fileRef.current }));
  };

  const onFileSelect = ({ target }) => {
    const file = [...target.files].pop();
    if (file) {
      if (filePreviewUrl) {
        URL.revokeObjectURL(filePreviewUrl);
      }

      const previewUrl = URL.createObjectURL(file);
      setFilePreviewUrl(previewUrl);

      fileRef.current = file;
    }
  };

  const getUserAvatar = () => {
    return user.profileUrl || filePreviewUrl || avatar;
  };

  return (
    <Drawer
      visible={active}
      title={"Profile"}
      width={"unset"}
      bodyStyle={{ padding: "24px 0" }}
      onClose={_onClose}
      className={classes.drawerFormWrapper}
      destroyOnClose
    >
      <div className={classes.drawer}>
        <div className={classes.drawerDetails}>
          <label className={classes.drawerImageButton} htmlFor="file">
            <img
              src={getUserAvatar()}
              alt="Avatar"
              className={classes.drawerImage}
            />
            <span className={classes.drawerImageLabel}>Edit</span>
            <input
              type="file"
              id="file"
              className={classes.drawerImageFileInput}
              onChange={onFileSelect}
              accept={"image/*"}
            />
          </label>
          <span className={classes.drawerName}>
            {user.firstName} {user.lastName}
          </span>
          <span className={classes.drawerEmail}>{user.email}</span>
        </div>
        <Form
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            oldPassword: "",
            newPassword: "",
          }}
          onFinish={onSubmit}
          form={form}
        >
          <div className={classes.drawerForm}>
            <Form.Item
              name={"firstName"}
              rules={[
                {
                  required: true,
                  message: "First name is required",
                },
              ]}
            >
              <Input placeholder={"First Name"} />
            </Form.Item>
            <Form.Item
              name={"lastName"}
              rules={[
                {
                  required: true,
                  message: "Last name is required",
                },
              ]}
            >
              <Input placeholder={"Last Name"} />
            </Form.Item>
            <Divider className={classes.drawerFormDivider} />
            <Form.Item name={"oldPassword"} rules={[oldPasswordValidator]}>
              <Input.Password placeholder={"Old Password"} />
            </Form.Item>
            <Form.Item name={"newPassword"} rules={[newPasswordValidator]}>
              <Input.Password placeholder={"New Password"} />
            </Form.Item>
          </div>
          <div className={classes.drawerFooter}>
            <Button className={classes.drawerFooterButton} onClick={onClose}>
              Back
            </Button>
            <Button
              type={"primary"}
              className={classNames(
                classes.drawerFooterSave,
                classes.drawerFooterButton
              )}
              htmlType={"submit"}
              loading={isLoading}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default UserDrawer;
