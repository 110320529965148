import React, { useEffect, useState } from "react";
import { Button, Upload, message } from "antd";
import classes from "./BatchUpload.module.css";
import uploadImage from "../../../../assets/images/upload.svg";
import progressSuccessIcon from "../../../../assets/images/progress-sucess.svg";
import successMessageIcon from "../../../../assets/images/success-message-check.svg";
import fileIcon from "../../../../assets/images/files.svg";
import warningIcon from "../../../../assets/images/warning.svg";
import Papa from "papaparse";
import { classNames } from "../../../../util/functions";
import { batchUploadActions, batchUploadSelectors } from "./batchUploadSlice";
import { useDispatch, useSelector } from "react-redux";
import { programsApi } from "../../../../Pages/Programs/programsApi";

const uploadStatuses = {
  PARSING: "PARSING",
  UPLOADING: "UPLOADING",
  UPLOAD_HAS_ERROR: "UPLOAD_HAS_ERROR",
  UPLOAD_SUCCESS: "UPLOAD_SUCCESS",
  FILE_ADDED: "FILE_ADDED",
  UPLOAD_HAS_NETWORK_ERROR: "UPLOAD_HAS_NETWORK_ERROR",
};

const BatchUpload = () => {
  const [status, setStatus] = useState(null);

  const [file, setFile] = useState({
    name: null,
    size: null,
    rawFile: null,
  });
  const [totalEntries, setTotalEntries] = useState(0);

  const [uploadProgress, setUploadProgress] = useState(0);

  const PARSE_MAX_WIDTH = 40;
  const UPLOAD_MAX_WIDTH = 80;

  useEffect(() => {
    let intervalID;
    if (uploadProgress > 0) {
      intervalID = setInterval(() => {
        if (uploadProgress >= PARSE_MAX_WIDTH) {
          return clearInterval(intervalID);
        }

        if (status === uploadStatuses.UPLOADING) {
          return clearInterval(intervalID);
        }

        setUploadProgress(Math.min(uploadProgress + 5, PARSE_MAX_WIDTH));
      }, 1000);
    }

    return () => clearInterval(intervalID);
  }, [uploadProgress, status]);

  const onFileSelect = (file) => {
    const size =
      file.size > 1000000
        ? `${file.size / 1000000}mb`
        : `${file.size / 1000}kb`;

    setFile({
      name: file.name,
      size,
    });

    setStatus(uploadStatuses.PARSING);
    Papa.parse(file, {
      header: true,
      worker: true,
      complete: processedRows,
    });

    setUploadProgress(5);
    return false;
  };

  const processedRows = (results) => {
    results.data.splice(results.data.length - 1, 1);
    setStatus(uploadStatuses.UPLOADING);
    setUploadProgress(PARSE_MAX_WIDTH + 5);
    setTotalEntries(results.data.length);
    dispatch(programsApi.uploadCandidates(results.data));
  };

  const dispatch = useDispatch();

  const resetRows = () => {
    setUploadProgress(0);
    setStatus(null);
    dispatch(batchUploadActions.reset());
  };

  const uploading = useSelector(batchUploadSelectors.uploading);
  const hasError = useSelector(batchUploadSelectors.hasError);
  const uploaded = useSelector(batchUploadSelectors.uploaded);
  const errorRows = useSelector(batchUploadSelectors.errorRows);
  const errors = useSelector(batchUploadSelectors.errors);
  const requestError = useSelector(batchUploadSelectors.requestError);

  useEffect(() => {
    if (hasError) {
      setStatus(uploadStatuses.UPLOAD_HAS_ERROR);
      setUploadProgress(100);
    }

    if (uploaded) {
      setStatus(uploadStatuses.UPLOAD_SUCCESS);
      setUploadProgress(100);
    }

    if (requestError) {
      message.error(requestError);
      dispatch(batchUploadActions.uploadCandidatesRequestNetworkError(null));
      setStatus(uploadStatuses.UPLOAD_HAS_NETWORK_ERROR);
      setUploadProgress(0);
    }
  }, [hasError, uploaded, requestError, dispatch]);

  useEffect(() => {
    let intervalID;
    if (uploading) {
      intervalID = setInterval(() => {
        if (uploadProgress >= UPLOAD_MAX_WIDTH) {
          return clearInterval(intervalID);
        }

        if (
          [
            uploadStatuses.UPLOAD_HAS_ERROR,
            uploadStatuses.UPLOAD_SUCCESS,
            uploadStatuses.UPLOAD_HAS_NETWORK_ERROR,
          ].includes(status)
        ) {
          return clearInterval(intervalID);
        }

        setUploadProgress(Math.min(uploadProgress + 5, UPLOAD_MAX_WIDTH));
      }, 500);
    }

    return () => clearInterval(intervalID);
  }, [uploading, status, uploadProgress]);

  return (
    <div className={classes.uploadWrapper}>
      <p className={classes.uploadHeader}>Upload</p>
      {status === null ? (
        <Upload.Dragger
          name={"CSVFile"}
          className={classes.upload}
          beforeUpload={onFileSelect}
          style={{ backgroundColor: "#F4F6F8" }}
          accept={".csv"}
        >
          <div className={classes.uploadBody}>
            <img
              src={uploadImage}
              alt="Upload"
              className={classes.uploadImage}
            />
            <p className={classes.uploadText}>
              Drop your excel sheet here, or click to upload
            </p>
          </div>
        </Upload.Dragger>
      ) : (
        <>
          <div className={classes.uploadProgress}>
            <span
              className={classes.uploadProgressIndicator}
              style={{ width: `${uploadProgress}%` }}
            />
            <img
              src={fileIcon}
              alt="File"
              className={classes.uploadProgressIcon}
            />
            <div className={classes.uploadProgressText}>
              <span className={classes.uploadProgressFileName}>
                {file.name}
              </span>
              <span className={classes.uploadProgressFileDetails}>
                {file.size}
              </span>
            </div>
            {status === uploadStatuses.UPLOAD_SUCCESS && (
              <img
                src={progressSuccessIcon}
                alt="Success"
                className={classes.uploadProgressSuccessIcon}
              />
            )}
          </div>
          {status === uploadStatuses.UPLOAD_HAS_ERROR && (
            <div
              className={classNames(
                classes.uploadStatusCard,
                classes.uploadStatusCardError
              )}
            >
              <div className={classes.uploadStatusCardTop}>
                <img
                  src={warningIcon}
                  alt="Error"
                  className={classes.uploadStatusCardIcon}
                />
                <span className={classes.uploadStatusCardTitle}>
                  Cell with errors
                </span>
              </div>
              <div className={classes.uploadStatusCardSection}>
                <p className={classes.uploadStatusCardSectionHeader}>Rows</p>
                <p className={classes.uploadStatusCardSectionText}>
                  {errorRows.join(", ")}
                </p>
              </div>
              <div className={classes.uploadStatusCardSection}>
                <p className={classes.uploadStatusCardSectionHeader}>
                  Insights
                </p>
                <p className={classes.uploadStatusCardSectionText}>
                  {errors.join(", ")}
                </p>
              </div>
            </div>
          )}
          {status === uploadStatuses.UPLOAD_SUCCESS && (
            <div
              className={classNames(
                classes.uploadStatusCard,
                classes.uploadStatusCardSuccess
              )}
            >
              <div className={classes.uploadStatusCardTop}>
                <img
                  src={successMessageIcon}
                  alt="Error"
                  className={classes.uploadStatusCardIcon}
                />
                <span className={classes.uploadStatusCardTitle}>
                  Upload Summary
                </span>
              </div>
              <div className={classes.uploadStatusCardSection}>
                <p className={classes.uploadStatusCardSectionHeader}>
                  Total Entries
                </p>
                <p className={classes.uploadStatusCardSectionText}>
                  {totalEntries}
                </p>
              </div>
            </div>
          )}
        </>
      )}
      <div className={classes.uploadButtons}>
        {(status === uploadStatuses.UPLOAD_HAS_ERROR ||
          status === uploadStatuses.UPLOAD_HAS_NETWORK_ERROR) && (
          <Button className={classes.uploadButton} onClick={resetRows}>
            Reset
          </Button>
        )}
      </div>
    </div>
  );
};

export default BatchUpload;
