import React from "react";
import { ReactComponent as Jobs } from "../../../assets/icons/jobs.svg";
import SideBarLink from "./SideBarLink/SideBarLink";
import { classNames } from "../../../util/functions";
import classes from "./SideBar.module.css";
import sidebarClasses from "./SideBarLink/SideBarLink.module.css";
import { ReactComponent as LogoutIcon } from "../../../assets/images/logout.svg";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userActions } from "../../../redux/userSlice";

const links = [
  {
    to: "/programs",
    icon: Jobs,
    text: "Programs",
  },
];

const SideBar = ({ active }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(userActions.logOut());
    history.push("auth/login");
  };

  return (
    <div
      className={classNames(classes.sideBar, active && classes.sideBarActive)}
    >
      {links.map(({ to, icon, text }) => (
        <SideBarLink to={to} icon={icon}>
          {text}
        </SideBarLink>
      ))}
      <button
        className={classNames(
          sidebarClasses.sideBarLink,
          classes.sideBarLogout
        )}
        onClick={logOut}
      >
        <LogoutIcon alt="Log Out" className={sidebarClasses.sideBarLinkIcon} />
        Logout
      </button>
    </div>
  );
};

export default SideBar;
