import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../util/constants";

const initialState = {
  status: Status.LOADING,
  errorMessage: null,
  data: [],
};

const comparisonGraphSlice = createSlice({
  name: "comparisonGraph",
  initialState,
  reducers: {
    getGraphData: (state) => {
      state.status = Status.LOADING;
    },
    getGraphDataSuccess: (state, action) => {
      state.status = Status.SUCCESS;
      state.data = action.payload;
    },
    getGraphDataError: (state, action) => {
      state.status = Status.FAILURE;
      state.errorMessage = action.payload;
    },
  },
});

const {
  getGraphData,
  getGraphDataSuccess,
  getGraphDataError,
} = comparisonGraphSlice.actions;

export const comparisonGraphActions = {
  getGraphData,
  getGraphDataSuccess,
  getGraphDataError,
};

export const comparisonGraphSelectors = {
  isFetching: (state) => state.comparisonGraph.status === Status.LOADING,
  hasError: (state) => state.comparisonGraph.status === Status.FAILURE,
  errorMessage: (state) => state.comparisonGraph.errorMessage,
  data: (state) => state.comparisonGraph.data,
};

export const comparisonGraphReducer = comparisonGraphSlice.reducer;
