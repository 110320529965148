import { programsActions } from "./programsSlice";
import { getErrorMessage } from "../../util/functions";
import { addProgramActions } from "../../components/Modals/AddProgramModal/addProgramSlice";
import { singleFormActions } from "../../components/Modals/UploadCandidatesModal/SingleForm/singleFormSlice";
import { batchUploadActions } from "../../components/Modals/UploadCandidatesModal/BatchUpload/batchUploadSlice";
import { makeRequest } from "../../api/api";

const getPrograms = () => async (dispatch) => {
  dispatch(programsActions.getProgramsRequest());
  try {
    const response = await makeRequest("programs");
    dispatch(programsActions.getProgramsRequestSuccess(response.data.data));
  } catch (e) {
    dispatch(programsActions.getProgramsRequestError(getErrorMessage(e)));
  }
};

const addProgram = ({ name, isCenter, centerOrCohort }) => async (dispatch) => {
  dispatch(addProgramActions.addProgramRequest());
  try {
    await makeRequest.post("programs", {
      name,
      isCenter,
      centerOrCohort,
    });
    dispatch(addProgramActions.addProgramRequestSuccess());
  } catch (e) {
    dispatch(addProgramActions.addProgramRequestError(getErrorMessage(e)));
  }
};

const getProgramCenters = (programId) => async (dispatch) => {
  dispatch(singleFormActions.getProgramCenters());
  try {
    const response = await makeRequest.get(`programs/${programId}/centers`);
    dispatch(
      singleFormActions.getProgramCentersSuccess({
        id: programId,
        centers: response.data.data,
      })
    );
  } catch (e) {
    dispatch(singleFormActions.getProgramCentersError(getErrorMessage(e)));
  }
};

const postCandidate = ({ firstName, lastName, programId, centerId }) => async (
  dispatch
) => {
  dispatch(singleFormActions.addCandidateRequest());
  try {
    await makeRequest.post("candidates", {
      firstName,
      lastName,
      programId,
      centerId,
    });
    dispatch(singleFormActions.addCandidateRequestSuccess());
  } catch (e) {
    dispatch(singleFormActions.addCandidateRequestError(getErrorMessage(e)));
  }
};

const uploadCandidates = (candidates) => async (dispatch) => {
  dispatch(batchUploadActions.uploadCandidatesRequest());
  try {
    await makeRequest.post("candidates/upload", candidates);
    dispatch(batchUploadActions.uploadCandidatesRequestSuccess());
  } catch (e) {
    if (!e.response?.data?.data) {
      return dispatch(
        batchUploadActions.uploadCandidatesRequestNetworkError(
          "Something went wrong, we're not sure what. Our team is working on it."
        )
      );
    }
    const { errorRows, errors } = e.response.data.data;

    dispatch(
      batchUploadActions.uploadCandidatesRequestError({
        errorRows,
        errors,
      })
    );
  }
};

const deleteProgram = async (id) => {
  try {
    await makeRequest.delete(`programs/${id}`);
  } catch (e) {
    console.log(e);
    throw new Error(getErrorMessage(e));
  }
};

export const programsApi = {
  getPrograms,
  addProgram,
  getProgramCenters,
  postCandidate,
  uploadCandidates,
  deleteProgram,
};
