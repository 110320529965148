import { Status } from "../../util/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: null,
  errorMessage: null,
  certificateData: null,
};

const downloadCertificateSlice = createSlice({
  name: "downloadCertificate",
  initialState,
  reducers: {
    getCertificateRequest: (state) => {
      state.status = Status.LOADING;
    },
    getCertificateRequestSuccess: (state, action) => {
      state.status = Status.SUCCESS;
      state.certificateData = action.payload;
    },
    getCertificateRequestError: (state, action) => {
      state.status = Status.FAILURE;
      state.errorMessage = action.payload;
    },
    resetStatus: (state) => initialState,
  },
});

const {
  getCertificateRequest,
  getCertificateRequestSuccess,
  getCertificateRequestError,
  resetStatus,
} = downloadCertificateSlice.actions;

export const downloadCertificateActions = {
  getCertificateRequest,
  getCertificateRequestSuccess,
  getCertificateRequestError,
  resetStatus,
};

export const downloadCertificateSelectors = {
  isFetchingCertificate: (state) =>
    state.downloadCertificate.status === Status.LOADING,
  fetchingCertificateErrorMessage: (state) =>
    state.downloadCertificate.errorMessage,
  fetchingCertificateHasError: (state) =>
    state.downloadCertificate.status === Status.FAILURE,
  fetchingCertificateSuccess: (state) =>
    state.downloadCertificate.status === Status.SUCCESS,
  certificateData: (state) => state.downloadCertificate.certificateData,
};

export const downloadCertificateReducer = downloadCertificateSlice.reducer;
