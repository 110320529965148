import * as axios from "axios";
import { setup } from "axios-cache-adapter";

export const makeRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const makeCachedRequest = setup({
  baseURL: process.env.REACT_APP_API_URL,
  cache: {
    maxAge: 15 * 60 * 1000,
  },
});
