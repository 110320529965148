import React, { useEffect } from "react";
import AuthContainer from "../AuthContainer";
import { Form, Input, message } from "antd";
import classes from "../Auth.module.css";
import Button from "antd/es/button";
import { Link } from "react-router-dom";
import { classNames } from "../../../util/functions";
import { authApi } from "../authApi";
import { useDispatch, useSelector } from "react-redux";
import Alert from "antd/es/alert";
import { signUpActions, signUpSelectors } from "./signUpSlice";
import { useHistory } from "react-router-dom";

const formInputs = [
  {
    name: "firstName",
    placeholder: "First Name",
    rules: [
      {
        required: true,
        message: "First Name is required",
      },
    ],
  },
  {
    name: "lastName",
    placeholder: "Last Name",
    rules: [
      {
        required: true,
        message: "Last Name is required",
      },
    ],
  },
  {
    name: "email",
    placeholder: "Email",
    rules: [
      {
        required: true,
        message: "Email is required",
      },
      {
        type: "email",
        message: "Please enter a valid email",
      },
    ],
  },
  {
    name: "password",
    placeholder: "Password",
    rules: [
      {
        required: true,
        message: "Password is required",
      },
    ],
    type: "password",
  },
];

const SignUp = () => {
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    dispatch(authApi.signUp(values));
  };

  const success = useSelector(signUpSelectors.success);
  const failure = useSelector(signUpSelectors.failure);
  const loading = useSelector(signUpSelectors.loading);
  const errorMessage = useSelector(signUpSelectors.errorMessage);

  const history = useHistory();

  useEffect(() => {
    if (success) {
      message.success("Successfully signed up. Redirecting");
      history.push("/auth/login");
      dispatch(signUpActions.reset());
    }
  }, [success, failure, history, dispatch]);

  return (
    <AuthContainer
      header={"Welcome to your certificate manager"}
      subtext={"Sign up to continue"}
      formHeader={"Sign Up"}
    >
      {failure && (
        <Alert
          message={"Something went wrong"}
          description={errorMessage}
          type={"error"}
          className={classes.status}
          closable
        />
      )}
      {success && (
        <Alert
          message={"Successfully signed up. Redirecting"}
          type={"success"}
          className={classes.status}
        />
      )}
      <Form
        className={classes.formInputs}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
        }}
        onFinish={onSubmit}
      >
        {formInputs.map(({ name, rules, placeholder, type = "text" }) => (
          <Form.Item name={name} rules={rules}>
            {type === "password" ? (
              <Input.Password
                placeholder={placeholder}
                aria-placeholder={placeholder}
              />
            ) : (
              <Input placeholder={placeholder} aria-placeholder={placeholder} />
            )}
          </Form.Item>
        ))}
        <Button type={"primary"} block htmlType={"submit"} loading={loading}>
          Sign Up
        </Button>
        <p className={classes.formRedirectText}>
          Already have an account?
          <Link
            className={classNames(
              classes.formLink,
              classes.formRedirectTextLink
            )}
            to={"/auth/login"}
          >
            {" "}
            Sign in
          </Link>
        </p>
      </Form>
    </AuthContainer>
  );
};

export default SignUp;
