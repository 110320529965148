import React, { useMemo } from "react";
import classes from "../ProgramCard/ProgramCard.module.css";
import { Card, message } from "antd";
import { ReactComponent as Cap } from "../../assets/icons/mortarboard.svg";
import CardActions from "../CardActions/CardActions";
import { onDeleteClick } from "../../util/functions";
import { programPageApi } from "../../Pages/Programs/ProgramPage/programPageApi";
import { useHistory } from "react-router-dom";

const CenterCard = ({
  id,
  name,
  programId,
  percentDownloaded,
  total,
  refreshCenters,
  onEdit,
}) => {
  const history = useHistory();

  const onCardClick = () => {
    history.push(`/programs/${programId}/${id}`);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDeleteCenter = async () => {
    try {
      await programPageApi.deleteCenter(id);
      message.success("Successfully deleted program");
      refreshCenters();
    } catch (error) {
      message.error(error.message);
    }
  };
  const CardTitle = useMemo(
    () => (
      <div className={classes.cardTitle}>
        <h5 className={classes.cardTitleHeader}>{name}</h5>
        <CardActions
          onDelete={() => onDeleteClick(onDeleteCenter, name)}
          onEdit={onEdit}
        />
      </div>
    ),
    [name, onDeleteCenter, onEdit]
  );

  return (
    <Card
      title={CardTitle}
      headStyle={{ borderBottom: "none" }}
      onClick={onCardClick}
      className={classes.card}
    >
      <Cap className={classes.cardIcon} />
      <div className={classes.cardNumbers}>
        <span className={classes.cardNumbersTotal}>{total}</span>
        <span className={classes.cardNumbersCohorts}>
          {percentDownloaded}% downloaded
        </span>
      </div>
    </Card>
  );
};

export default CenterCard;
