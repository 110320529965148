import { comparisonGraphActions } from "../../../components/CenterComparisonGraph/comparisonGraphSlice";
import { getErrorMessage, transformData } from "../../../util/functions";
import { programPageActions } from "./programSlice";
import { addCentersActions } from "../../../components/Modals/AddCentersModal/addCentersSlice";
import { makeRequest } from "../../../api/api";
import moment from "moment";

const getProgramGraph = (programId) => async (dispatch) => {
  dispatch(comparisonGraphActions.getGraphData());
  try {
    const twoWeeksAgo = moment().subtract(2, "weeks").format();

    const response = await makeRequest.get(`programs/${programId}/graph`, {
      params: {
        backby: twoWeeksAgo,
      },
    });
    const transformedData = transformData(response.data.data);
    dispatch(comparisonGraphActions.getGraphDataSuccess(transformedData));
  } catch (e) {
    console.log(e);
    dispatch(comparisonGraphActions.getGraphDataError(getErrorMessage(e)));
  }
};

const getProgramCenters = (programId) => async (dispatch) => {
  dispatch(programPageActions.getProgramCentersRequest());
  try {
    const response = await makeRequest.get(`programs/${programId}/centers`);
    dispatch(
      programPageActions.getProgramCentersRequestSuccess(response.data.data)
    );
  } catch (e) {
    dispatch(
      programPageActions.getProgramCentersRequestError(getErrorMessage(e))
    );
  }
};

const getProgramData = (programId) => async (dispatch) => {
  dispatch(programPageActions.getProgramRequest());
  try {
    const response = await makeRequest(`programs/${programId}`);
    dispatch(programPageActions.getProgramRequestSuccess(response.data.data));
  } catch (e) {
    dispatch(programPageActions.getProgramRequestError(getErrorMessage(e)));
  }
};

const postCentersData = (centers) => async (dispatch) => {
  dispatch(addCentersActions.addCentersRequest());
  try {
    await makeRequest.post(
      "centers",
      centers.filter(({ name }) => name.trim())
    );
    dispatch(addCentersActions.addCentersRequestSuccess());
  } catch (e) {
    dispatch(addCentersActions.addCentersRequestError(getErrorMessage(e)));
  }
};

const deleteCenter = async (centerId) => {
  try {
    await makeRequest.delete(`centers/${centerId}`);
  } catch (e) {
    throw new Error(getErrorMessage(e));
  }
};

export const programPageApi = {
  getProgramGraph,
  getProgramCenters,
  getProgramData,
  postCentersData,
  deleteCenter,
};
