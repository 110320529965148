import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../../util/constants";

const initialState = {
  status: Status.LOADING,
  errorMessage: null,
  centers: [],
  programStatus: Status.LOADING,
  programErrorMessage: null,
  program: {},
};

const programSlice = createSlice({
  name: "program",
  initialState,
  reducers: {
    getProgramCentersRequest: (state) => {
      state.status = Status.LOADING;
    },
    getProgramCentersRequestSuccess: (state, action) => {
      state.status = Status.SUCCESS;
      state.centers = action.payload;
    },
    getProgramCentersRequestError: (state, action) => {
      state.status = Status.FAILURE;
      state.errorMessage = action.payload;
    },
    getProgramRequest: (state) => {
      state.programStatus = Status.LOADING;
    },
    getProgramRequestSuccess: (state, action) => {
      state.programStatus = Status.SUCCESS;
      state.program = action.payload;
    },
    getProgramRequestError: (state, action) => {
      state.programStatus = Status.FAILURE;
      state.programErrorMessage = action.payload;
    },
    resetProgram: (state) => ({
      ...state,
      programStatus: Status.LOADING,
      programErrorMessage: null,
      program: {},
    }),
  },
});

const {
  getProgramCentersRequest,
  getProgramCentersRequestSuccess,
  getProgramCentersRequestError,
  getProgramRequest,
  getProgramRequestSuccess,
  getProgramRequestError,
  resetProgram,
} = programSlice.actions;

export const programPageActions = {
  getProgramCentersRequest,
  getProgramCentersRequestSuccess,
  getProgramCentersRequestError,
  getProgramRequest,
  getProgramRequestSuccess,
  getProgramRequestError,
  resetProgram,
};

export const programPageSelectors = {
  isFetching: (state) => state.program.status === Status.LOADING,
  hasError: (state) => state.program.status === Status.FAILURE,
  errorMessage: (state) => state.program.errorMessage,
  centers: (state) => state.program.centers,
  programIsFetching: (state) => state.program.programStatus === Status.LOADING,
  programHasError: (state) => state.program.programStatus === Status.FAILURE,
  programErrorMessage: (state) => state.program.programErrorMessage,
  program: (state) => state.program.program,
};

export const programReducer = programSlice.reducer;
