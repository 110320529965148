import React, { useEffect } from "react";
import AuthContainer from "../AuthContainer";
import { Button, Form, Input } from "antd";
import classes from "../Auth.module.css";
import { Link } from "react-router-dom";
import { classNames } from "../../../util/functions";
import { authApi } from "../authApi";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPasswordActions,
  forgotPasswordSelectors,
} from "./forgotPasswordSlice";
import Alert from "antd/es/alert";

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    dispatch(authApi.forgotPassword(values.email));
  };

  const success = useSelector(forgotPasswordSelectors.success);
  const failure = useSelector(forgotPasswordSelectors.failure);
  const loading = useSelector(forgotPasswordSelectors.loading);
  const errorMessage = useSelector(forgotPasswordSelectors.errorMessage);

  useEffect(() => {
    return () => {
      dispatch(forgotPasswordActions.reset());
    };
  }, [success, failure, dispatch]);

  return (
    <AuthContainer
      header={"Forgot Password?"}
      subtext={"Provide the email you used in registering your account"}
      formHeader={!success ? "Enter your email" : "Mail sent successfully"}
    >
      {!success ? (
        <Form
          className={classes.formInputs}
          initialValues={{
            email: "",
          }}
          onFinish={onSubmit}
        >
          {failure && (
            <Alert
              message={"Something went wrong"}
              description={errorMessage}
              type={"error"}
              className={classes.status}
              closable
            />
          )}
          <Form.Item
            name={"email"}
            rules={[
              {
                required: true,
                message: "Email is required",
              },
              {
                type: "email",
                message: "Please input a valid email",
              },
            ]}
          >
            <Input
              placeholder={"Email Address"}
              aria-placeholder={"Email Address"}
            />
          </Form.Item>
          <Button type={"primary"} htmlType={"submit"} block loading={loading}>
            Send link
          </Button>
          <Link
            to={"/auth/login"}
            className={classNames(classes.formLink, classes.formLinkBottom)}
          >
            Remember password?
          </Link>
        </Form>
      ) : (
        <p className={classes.successText}>
          A password reset mail has been sent to your registered email. Kindly
          open to mail and follow the instructions
        </p>
      )}
    </AuthContainer>
  );
};

export default ForgotPassword;
