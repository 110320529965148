import React, { useEffect, useState } from "react";
import AuthContainer from "../AuthContainer";
import { message, Button, Form, Input } from "antd";
import classes from "../Auth.module.css";
import { useDispatch, useSelector } from "react-redux";
import { authApi } from "../authApi";
import {
  resetPasswordActions,
  resetPasswordSelectors,
} from "./resetPasswordSlice";
import Alert from "antd/es/alert";
import { Link, Redirect, useLocation } from "react-router-dom";

const ResetPassword = () => {
  const dispatch = useDispatch();

  const [redirect, setRedirect] = useState(false);

  const location = useLocation();

  const onSubmit = (values) => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const email = searchParams.get("email");

    dispatch(
      authApi.resetPassword({
        newPassword: values.newPassword,
        code,
        email,
      })
    );
  };

  const success = useSelector(resetPasswordSelectors.success);
  const failure = useSelector(resetPasswordSelectors.failure);
  const loading = useSelector(resetPasswordSelectors.loading);
  const errorMessage = useSelector(resetPasswordSelectors.errorMessage);

  useEffect(() => {
    if (success) {
      message.success("Successfully reset password");
      setRedirect(true);
      dispatch(resetPasswordActions.reset());
    }
  }, [success, failure, dispatch]);

  return (
    <AuthContainer
      header={"Reset Password?"}
      subtext={
        "We advice you use alphanumeric password, to increase the security"
      }
      formHeader={success ? "Password reset successful" : "Enter new password"}
    >
      {redirect && <Redirect to={"/auth/login"} push />}
      {!success ? (
        <Form
          initialValues={{
            newPassword: "",
          }}
          onFinish={onSubmit}
          className={classes.formInputs}
        >
          {failure && (
            <Alert
              message={"Something went wrong"}
              description={errorMessage}
              type={"error"}
              className={classes.status}
              closable
            />
          )}
          <Form.Item
            name={"newPassword"}
            rules={[
              {
                required: true,
                message: "New Password is required",
              },
            ]}
          >
            <Input.Password
              placeholder={"New Password"}
              aria-placeholder={"New Password"}
            />
          </Form.Item>
          <Button block htmlType={"submit"} type={"primary"} loading={loading}>
            Reset Password
          </Button>
        </Form>
      ) : (
        <p className={classes.successText}>
          You will be redirected to the sign in page, if not, kindly click{" "}
          <Link to={"/auth/login"} className={classes.successTextBold}>
            here
          </Link>{" "}
          to sign in.
        </p>
      )}
    </AuthContainer>
  );
};

export default ResetPassword;
