import { centerPageActions } from "./centerPageSlice";
import { getErrorMessage, transformTableData } from "../../../util/functions";
import { makeRequest } from "../../../api/api";

const getCandidates = ({ centerId, search, downloadStatus }) => async (
  dispatch
) => {
  dispatch(centerPageActions.getCandidatesRequest());
  try {
    const params = {
      keyword: search || null,
      downloadStatus,
    };

    const response = await makeRequest(`centers/${centerId}/candidates`, {
      params,
    });
    dispatch(
      centerPageActions.getCandidatesRequestSuccess(
        transformTableData(response.data.data)
      )
    );
  } catch (e) {
    dispatch(centerPageActions.getCandidatesRequestError(getErrorMessage(e)));
  }
};

const getCenterData = (centerId) => async (dispatch) => {
  dispatch(centerPageActions.getCenterRequest());
  try {
    const response = await makeRequest(`centers/${centerId}`);
    dispatch(centerPageActions.getCenterRequestSuccess(response.data.data));
  } catch (e) {
    dispatch(centerPageActions.getCenterRequestError(getErrorMessage(e)));
  }
};

const resendCertificate = (candidateId) => async (dispatch) => {
  dispatch(centerPageActions.resendCertificate());
  try {
    await makeRequest.get(`candidates/${candidateId}/mark-as-undownloaded`);
    dispatch(centerPageActions.resendCertificateSuccess());
  } catch (e) {
    dispatch(centerPageActions.resendCertificateError(getErrorMessage(e)));
  }
};

export const centerPageApi = {
  getCandidates,
  getCenterData,
  resendCertificate,
};
