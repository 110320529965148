import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { rootReducer } from "./rootReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import expireReducer from "redux-persist-expire";

const prodMiddleware = [];
const devMiddleware = [logger];

let middleware = [...getDefaultMiddleware(), ...prodMiddleware];

if (process.env.NODE_ENV !== "production") {
  middleware = [...middleware, ...devMiddleware];
}

const persistConfig = {
  key: "root",
  whitelist: ["user"],
  storage,
  transforms: [
    expireReducer("user", {
      expireSeconds: 86400 * 14, //Expire after 14 days
      expiredState: {
        isLoggedIn: false,
        userDetails: {},
        token: null,
      },
      autoExpire: true,
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: middleware,
});

export const persistor = persistStore(store);

export default store;
