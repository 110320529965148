import React, { useCallback, useEffect, useRef, useState } from "react";
import classes from "./UploadCertificateModal.module.css";
import Modal from "antd/es/modal";
import uploadImage from "../../../assets/images/upload.svg";
import { Divider, Upload, message, Form } from "antd";
import InputNumber from "antd/es/input-number";
import Button from "antd/es/button";
import { Select } from "antd/es";
import PDFObject from "pdfobject";
import { addTextToPdf, classNames } from "../../../util/functions";
import { useDispatch, useSelector } from "react-redux";
import { uploadCertificateApi } from "./uploadCertificateApi";
import {
  uploadCertificateActions,
  uploadCertificateSelectors,
} from "./uploadCertificateSlice";
import useProgramCenters from "../../../hooks/useProgramCenters";

const FONT_SIZE = 20;

const UploadCertificateModal = ({ programId, onClose, visible, centerId }) => {
  const [pdfPreviewActive, setPdfPreviewActive] = useState(false);

  const fileUrl = useRef("");
  const fileRef = useRef("");
  const fileArray = useRef("");

  const onFileSelect = (file) => {
    if (!verifyFile(file)) {
      return false;
    }

    if (fileUrl.current) {
      URL.revokeObjectURL(fileUrl.current);
    }

    fileUrl.current = URL.createObjectURL(file);
    fileRef.current = file;

    const fileReader = new FileReader();

    const hideMessage = message.loading("Loading file");

    fileReader.onload = () => {
      fileArray.current = fileReader.result;
      hideMessage();
    };

    setPdfPreviewActive(true);

    fileReader.readAsArrayBuffer(file);

    PDFObject.embed(fileUrl.current, `#uploadPreview`);

    return false;
  };

  const [form] = Form.useForm();
  const onPreview = async () => {
    const distanceFromTop = form.getFieldValue("distanceFromTop");

    const blob = await addTextToPdf({
      fileArray: fileArray.current,
      fontSize: FONT_SIZE,
      text: "Test User",
      distanceFromTop,
    });

    //Revoke previous object url
    if (fileUrl.current) {
      URL.revokeObjectURL(fileUrl.current);
    }

    fileUrl.current = URL.createObjectURL(blob);

    PDFObject.embed(fileUrl.current, "#uploadPreview");
  };

  const verifyFile = (file) => {
    const isJpgOrPng = file.type === "application/pdf";
    let valid = true;

    if (!isJpgOrPng) {
      message.error("You can only upload a pdf file!");
      valid = false;
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("File must smaller than 2MB!");
      valid = false;
    }

    return valid;
  };

  const dispatch = useDispatch();

  const isFetching = useSelector(uploadCertificateSelectors.isFetching);
  const success = useSelector(uploadCertificateSelectors.success);
  const hasError = useSelector(uploadCertificateSelectors.hasError);
  const errorMessage = useSelector(uploadCertificateSelectors.errorMessage);

  const _onClose = useCallback(() => {
    dispatch(uploadCertificateActions.reset());
    onClose();
    fileUrl.current = "";
    fileRef.current = "";
    fileArray.current = "";
    setPdfPreviewActive(false);

    if (fileUrl.current) {
      URL.revokeObjectURL(fileUrl.current);
    }
  }, [dispatch, onClose]);

  useEffect(() => {
    if (success) {
      message.success("Certificate uploaded successfully");
      _onClose();
    }

    if (hasError) {
      message.error(errorMessage);
    }

    return () => {
      if (fileUrl.current) {
        URL.revokeObjectURL(fileUrl.current);
      }
    };
  }, [_onClose, dispatch, errorMessage, hasError, onClose, success]);

  const onSubmit = (formValues) => {
    dispatch(
      uploadCertificateApi.postTemplate({
        programId,
        distanceFromTop: formValues.distanceFromTop,
        centerId: formValues.addCenter,
        file: fileRef.current,
      })
    );
  };
  const { centers, isFetchingCenters } = useProgramCenters(programId);

  return (
    <Modal
      visible={visible}
      onCancel={_onClose}
      width={"1000px"}
      footer={null}
      bodyStyle={{
        padding: "0",
      }}
    >
      <div className={classes.modal}>
        <div className={classes.uploadWrapper}>
          <div
            className={classNames(
              classes.uploadPreview,
              pdfPreviewActive && classes.uploadPreviewActive
            )}
            id={"uploadPreview"}
          />
          {pdfPreviewActive && (
            <Button
              type={"link"}
              className={classes.uploadPreviewReset}
              onClick={(_) => setPdfPreviewActive(false)}
            >
              Reset
            </Button>
          )}
          {!pdfPreviewActive && (
            <Upload.Dragger
              name={"CSVFile"}
              className={classes.upload}
              beforeUpload={onFileSelect}
              style={{ backgroundColor: "#F4F6F8" }}
              accept={".pdf"}
            >
              <div className={classes.uploadBody}>
                <img
                  src={uploadImage}
                  alt="Upload"
                  className={classes.uploadImage}
                />
                <p className={classes.uploadText}>
                  Drop your pdf file here, or click to upload
                </p>
              </div>
            </Upload.Dragger>
          )}
        </div>
        <Divider type={"vertical"} className={classes.splitLine} />
        <div>
          <Form className={classes.form} form={form} onFinish={onSubmit}>
            <Form.Item
              label={"Distance From Top"}
              name={"distanceFromTop"}
              rules={[
                {
                  required: true,
                  message: "Distance from top is required",
                },
              ]}
            >
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item
              label={"Add Center"}
              name={"addCenter"}
              rules={[
                {
                  required: true,
                  message: "Add Center from top is required",
                },
              ]}
              className={classes.stepFormInput}
            >
              <Select placeholder={"Center"} loading={isFetchingCenters}>
                {centers[programId]?.map(({ id, name }) => (
                  <Select.Option value={id}>{name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div className={classes.formButtons}>
              <Button htmlType={"button"} onClick={onPreview}>
                Preview
              </Button>
              <Button
                type={"primary"}
                htmlType={"submit"}
                loading={isFetching}
                disabled={isFetching}
              >
                Upload
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default UploadCertificateModal;
