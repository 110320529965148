import React from "react";
import { Button, Dropdown, Menu } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";

const CardActions = ({ onDelete, onEdit }) => {
  const renderOptions = () => (
    <Menu>
      <Menu.Item onClick={onEdit}>Edit</Menu.Item>
      <Menu.Item onClick={onDelete}>Delete</Menu.Item>
    </Menu>
  );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown trigger={["click"]} overlay={renderOptions()}>
        <Button
          icon={<EllipsisOutlined style={{ transform: "rotate(90deg)" }} />}
          type={"text"}
        />
      </Dropdown>
    </div>
  );
};

export default CardActions;
