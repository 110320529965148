import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../../util/constants";

const initialState = {
  status: null,
  errorMessage: null,
};

const previewCertificateSlice = createSlice({
  name: "previewCertificate",
  initialState,
  reducers: {
    markAsReadRequest: (state) => {
      state.status = Status.LOADING;
    },
    markAsReadRequestSuccess: (state) => {
      state.status = Status.SUCCESS;
    },
    markAsReadRequestError: (state, action) => {
      state.status = Status.FAILURE;
      state.errorMessage = action.payload;
    },
    reset: () => initialState,
  },
});

const {
  markAsReadRequest,
  markAsReadRequestSuccess,
  markAsReadRequestError,
  reset,
} = previewCertificateSlice.actions;

export const previewCertificateActions = {
  markAsReadRequest,
  markAsReadRequestSuccess,
  markAsReadRequestError,
  reset,
};

export const previewCertificateSelectors = {
  isFetching: (state) => state.previewCertificate.status === Status.LOADING,
  hasError: (state) => state.previewCertificate.status === Status.FAILURE,
  success: (state) => state.previewCertificate.status === Status.SUCCESS,
  errorMessage: (state) => state.previewCertificate.errorMessage,
};

export const previewCertificateReducer = previewCertificateSlice.reducer;
