import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../../util/constants";

const initialState = {
  status: null,
  errorMessage: null,
};

const uploadCertificateSlice = createSlice({
  name: "uploadCertificate",
  initialState,
  reducers: {
    postTemplateRequest: (state) => {
      state.status = Status.LOADING;
    },
    postTemplateRequestSuccess: (state) => {
      state.status = Status.SUCCESS;
    },
    postTemplateRequestError: (state, action) => {
      state.status = Status.FAILURE;
      state.errorMessage = action.payload;
    },
    reset: (state) => initialState,
  },
});

const {
  postTemplateRequest,
  postTemplateRequestSuccess,
  postTemplateRequestError,
  reset,
} = uploadCertificateSlice.actions;

export const uploadCertificateActions = {
  postTemplateRequest,
  postTemplateRequestSuccess,
  postTemplateRequestError,
  reset,
};

export const uploadCertificateSelectors = {
  isFetching: (state) => state.uploadCertificate.status === Status.LOADING,
  success: (state) => state.uploadCertificate.status === Status.SUCCESS,
  hasError: (state) => state.uploadCertificate.status === Status.FAILURE,
  errorMessage: (state) => state.uploadCertificate.errorMessage,
};

export const uploadCertificateReducer = uploadCertificateSlice.reducer;
