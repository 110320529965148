import React, { useEffect, useState } from "react";
import { Input, Modal, message } from "antd";
import classes from "./EditModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { editModalApi } from "./editModalApi";
import { editModalActions, editModalSelectors } from "./editModalSlice";

export const EditModalTypes = {
  program: "Program",
  center: "Center",
};

const EditModal = ({ name: initialName, id, type, visible, onClose }) => {
  const [name, setName] = useState(initialName);
  const dispatch = useDispatch();

  const _onClose = () => {
    onClose(success);
    dispatch(editModalActions.reset());
  };

  const isLoading = useSelector(editModalSelectors.isLoading);
  const hasError = useSelector(editModalSelectors.hasError);
  const success = useSelector(editModalSelectors.success);
  const errorMessage = useSelector(editModalSelectors.errorMessage);

  useEffect(() => {
    if (success) {
      message.success("Successfully edited name");
      _onClose();
    }

    if (hasError) {
      message.error(errorMessage);
      dispatch(editModalActions.reset());
    }

    return () => {
      dispatch(editModalActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage, hasError, success]);

  useEffect(() => {
    setName(initialName);
  }, [initialName]);

  const onSubmit = () => {
    if (type === EditModalTypes.center) {
      dispatch(editModalApi.editCenter(id, name));
    } else if (type === EditModalTypes.program) {
      dispatch(editModalApi.editProgram(id, name));
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        visible={visible}
        onCancel={_onClose}
        confirmLoading={isLoading}
        onOk={onSubmit}
        okButtonProps={{
          disabled: !name,
        }}
      >
        <h3 className={classes.editModalHeader}>Edit {type} Name</h3>
        <Input
          value={name}
          onChange={({ target }) => setName(target.value)}
          disabled={isLoading}
        />
      </Modal>
    </div>
  );
};

export default EditModal;
