import { userDrawerActions } from "./userDrawerSlice";
import { getErrorMessage } from "../../../util/functions";
import { makeRequest } from "../../../api/api";
import { userActions } from "../../../redux/userSlice";

const updateProfile = ({
  firstName,
  lastName,
  oldPassword,
  newPassword,
  file,
}) => async (dispatch) => {
  dispatch(userDrawerActions.userDrawerRequest());

  try {
    if (file) {
      const formData = new FormData();
      formData.append("picture", file);

      await makeRequest.patch("auth/profile-picture", formData);
    }

    const response = await makeRequest.patch(`auth/profile`, {
      firstName,
      lastName,
      oldPassword,
      newPassword,
    });

    const {
      firstName: newFirstName,
      lastName: newLastName,
      profileUrl,
    } = response.data.data;

    dispatch(userDrawerActions.userDrawerRequestSuccess());

    dispatch(
      userActions.updateUserDetails({
        firstName: newFirstName,
        lastName: newLastName,
        profileUrl,
      })
    );
  } catch (e) {
    dispatch(userDrawerActions.userDrawerRequestError(getErrorMessage(e)));
  }
};

export const userDrawerApi = {
  updateProfile,
};
