import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Divider, Modal, Spin, message } from "antd";
import classes from "./PreviewCertificateModal.module.css";
import { makeCachedRequest } from "../../../api/api";
import PDFObject from "pdfobject";
import { useDispatch, useSelector } from "react-redux";
import { downloadCertificateApi } from "../../../Pages/DownloadCertificate/downloadCertificateApi";
import {
  previewCertificateActions,
  previewCertificateSelectors,
} from "./previewCertificateSlice";
import { addTextToPdf } from "../../../util/functions";

const FONT_SIZE = 20;

const PreviewCertificateModal = ({ data, onClose, active }) => {
  const [fetchingPdfData, setFetchingPdfData] = useState(true);

  const wrapperRef = useRef();
  const objectUrl = useRef("");

  const fetchCertificate = useCallback(() => {
    setFetchingPdfData(true);
    const { url, distanceFromTop } = data.certificateData;
    const { firstName, lastName } = data;

    const fullName = `${firstName} ${lastName}`;

    makeCachedRequest
      .get(url, {
        responseType: "arraybuffer",
        baseURL: "",
      })
      .then(async (response) => {
        const blob = await addTextToPdf({
          fileArray: response.data,
          distanceFromTop,
          text: fullName,
          fontSize: FONT_SIZE,
        });

        //Revoke previous object url
        if (objectUrl.current) {
          URL.revokeObjectURL(objectUrl.current);
        }

        objectUrl.current = URL.createObjectURL(blob);
        setFetchingPdfData(false);
        PDFObject.embed(objectUrl.current, "#wrapper");
      })
      .catch((e) => {
        message.error(
          "Something went wrong while loading the document. Kindly retry"
        );
        setFetchingPdfData(false);
      });
  }, [data]);

  useEffect(() => {
    if (data) {
      fetchCertificate();
    }

    return () => {
      if (objectUrl.current) {
        URL.revokeObjectURL(objectUrl.current);
      }
    };
  }, [data, fetchCertificate]);

  const _onClose = () => {
    onClose();
    setFetchingPdfData(true);
    if (objectUrl.current) {
      URL.revokeObjectURL(objectUrl.current);
    }
  };

  const dispatch = useDispatch();

  const downloadCertificate = () => {
    const { certNo } = data;
    dispatch(
      downloadCertificateApi.markCertificateAsDownloaded({ id: certNo })
    );
  };

  const isFetching = useSelector(previewCertificateSelectors.isFetching);
  const hasError = useSelector(previewCertificateSelectors.hasError);
  const success = useSelector(previewCertificateSelectors.success);
  const errorMessage = useSelector(previewCertificateSelectors.errorMessage);

  const onSuccess = useCallback(() => {
    const { firstName } = data;
    const link = document.createElement("a");
    link.href = objectUrl.current;
    link.download = `${firstName}'s Certificate`;
    document.body.appendChild(link);
    link.click();
    link.remove();
    dispatch(previewCertificateActions.reset());
  }, [data, dispatch]);

  useEffect(() => {
    if (success) {
      onSuccess();
    }

    if (hasError) {
      message.error(errorMessage);
      dispatch(previewCertificateActions.reset());
    }
  }, [dispatch, errorMessage, hasError, onSuccess, success]);

  return (
    <Modal
      visible={active}
      width={"1000px"}
      footer={null}
      closable={false}
      bodyStyle={{
        padding: "40px",
      }}
      onCancel={_onClose}
    >
      <div className={classes.modal}>
        <div
          className={classes.modalCertificatePreview}
          ref={wrapperRef}
          id={"wrapper"}
        >
          {fetchingPdfData && <Spin tip={"Loading Document"} />}
        </div>
        <Divider className={classes.modalSplitLine} type={"vertical"} />
        <div className={classes.modalControls}>
          <Button
            type={"primary"}
            disabled={fetchingPdfData}
            loading={isFetching || fetchingPdfData}
            onClick={downloadCertificate}
          >
            Download PDF
          </Button>
          <Button
            type={"link"}
            className={classes.returnButton}
            onClick={_onClose}
          >
            Return Home
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PreviewCertificateModal;
