import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../../util/constants";

const initialState = {
  status: null,
  errorMessage: null,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginRequestSuccess: (state) => {
      state.status = Status.SUCCESS;
    },
    loginRequestFailure: (state, action) => {
      state.status = Status.FAILURE;
      state.errorMessage = action.payload;
    },
    reset: (state) => initialState,
  },
});

const { loginRequestSuccess, loginRequestFailure, reset } = loginSlice.actions;

export const loginActions = {
  loginRequestSuccess,
  loginRequestFailure,
  reset,
};

export const loginSelectors = {
  success: (state) => state.login.status === Status.SUCCESS,
  failure: (state) => state.login.status === Status.FAILURE,
  loading: (state) => state.login.status === Status.LOADING,
  errorMessage: (state) => state.login.errorMessage,
};

export const loginReducer = loginSlice.reducer;
