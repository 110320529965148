import React from "react";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../redux/userSlice";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ component, path, ...props }) => {
  const isLoggedIn = useSelector(userSelectors.isLoggedIn);

  if (isLoggedIn) {
    return <Route path={path} component={component} {...props} />;
  }

  return <Redirect to={"/auth/login"} />;
};

export default PrivateRoute;
