import React from "react";
import "./App.less";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./Pages/Auth/Login/Login";
import SignUp from "./Pages/Auth/SignUp/SignUp";
import ForgotPassword from "./Pages/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./Pages/Auth/ResetPassword/ResetPassword";
import DownloadCertificate from "./Pages/DownloadCertificate/DownloadCertificate";
import { PersistGate } from "redux-persist/integration/react";
import PrivateRoute from "./components/Authorization/Routes/PrivateRoute";
import ProgramsIndex from "./Pages/Programs/ProgramsIndex";
import AuthCheck from "./components/Authorization/AuthCheck/AuthCheck";

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AuthCheck>
          <Router>
            <Switch>
              <Route path={"/auth/login"} component={Login} />
              <Route path={"/auth/sign-up"} component={SignUp} />
              <Route
                path={"/auth/forgot-password"}
                component={ForgotPassword}
              />
              <Route path={"/auth/reset-password"} component={ResetPassword} />
              <Route
                path={"/download-certificate"}
                component={DownloadCertificate}
              />
              <Route
                path={"/"}
                exact
                render={(_) => <Redirect to={"/programs"} />}
              />
              <PrivateRoute path={"/programs"} component={ProgramsIndex} />
            </Switch>
          </Router>
        </AuthCheck>
      </PersistGate>
    </Provider>
  );
}

export default App;
