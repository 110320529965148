import React from "react";
import classes from "./SideBarLink.module.css";
import { NavLink } from "react-router-dom";

const SideBarLink = ({ icon: Icon, children, to }) => {
  return (
    <NavLink
      className={classes.sideBarLink}
      activeClassName={classes.sideBarLinkActive}
      to={to}
    >
      <Icon className={classes.sideBarLinkIcon} />
      {children}
    </NavLink>
  );
};

export default SideBarLink;
