import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  userDetails: {},
  token: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logIn: (state, action) => {
      state.isLoggedIn = true;
      const { token, userDetails } = action.payload;
      state.userDetails = userDetails;
      state.token = token;
    },
    logOut: (state) => {
      state.isLoggedIn = false;
      state.userDetails = {};
      state.token = null;
    },
    updateUserDetails: (state, action) => {
      state.userDetails = {
        ...state.userDetails,
        ...action.payload,
      };
    },
  },
});

const { logIn, logOut, updateUserDetails } = userSlice.actions;

export const userActions = {
  logIn,
  logOut,
  updateUserDetails,
};

export const userSelectors = {
  isLoggedIn: (state) => state.user.isLoggedIn,
  token: (state) => state.user.token,
  userDetails: (state) => state.user.userDetails,
};

export const userReducer = userSlice.reducer;
