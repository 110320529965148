import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../../util/constants";

const initialState = {
  status: null,
  errorMessage: null,
};

const addProgramSlice = createSlice({
  name: "addProgram",
  initialState,
  reducers: {
    addProgramRequest: (state) => {
      state.status = Status.LOADING;
    },
    addProgramRequestSuccess: (state, action) => {
      state.status = Status.SUCCESS;
    },
    addProgramRequestError: (state, action) => {
      state.status = Status.FAILURE;
      state.errorMessage = action.payload;
    },
    reset: (state) => initialState,
  },
});

const {
  addProgramRequest,
  addProgramRequestSuccess,
  addProgramRequestError,
  reset,
} = addProgramSlice.actions;

export const addProgramActions = {
  addProgramRequest,
  addProgramRequestSuccess,
  addProgramRequestError,
  reset,
};

export const addProgramSelectors = {
  isLoading: (state) => state.addProgram.status === Status.LOADING,
  hasError: (state) => state.addProgram.status === Status.FAILURE,
  success: (state) => state.addProgram.status === Status.SUCCESS,
  errorMessage: (state) => state.addProgram.errorMessage,
};

export const addProgramReducer = addProgramSlice.reducer;
