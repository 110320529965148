import { Status } from "../../util/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: null,
  errorMessage: null,
};

const editModalSlice = createSlice({
  name: "editModal",
  initialState,
  reducers: {
    editRequest: (state) => {
      state.status = Status.LOADING;
    },
    editRequestSuccess: (state) => {
      state.status = Status.SUCCESS;
    },
    editRequestFailure: (state, action) => {
      state.status = Status.FAILURE;
      state.errorMessage = action.payload;
    },
    reset: (state) => initialState,
  },
});

const {
  editRequest,
  editRequestSuccess,
  editRequestFailure,
  reset,
} = editModalSlice.actions;

export const editModalActions = {
  editRequest,
  editRequestSuccess,
  editRequestFailure,
  reset,
};

export const editModalSelectors = {
  isLoading: (state) => state.editModal.status === Status.LOADING,
  hasError: (state) => state.editModal.status === Status.FAILURE,
  success: (state) => state.editModal.status === Status.SUCCESS,
  errorMessage: (state) => state.editModal.errorMessage,
};

export const editModalReducer = editModalSlice.reducer;
