import React, { useCallback, useState } from "react";
import NavBar from "../NavBar/NavBar";
import classes from "./Container.module.css";
import SideBar from "../SideBar/SideBar";
import UserDrawer from "../UserDrawer/UserDrawer";

const Container = ({ children }) => {
  const [sidebarActive, setSidebarActive] = useState(false);
  const [userDrawerActive, setUserDrawerActive] = useState(false);

  const onDrawerClose = useCallback(() => {
    setUserDrawerActive(false);
  }, []);

  const onToggleSideBar = useCallback(() => {
    setSidebarActive(!sidebarActive);
  }, [sidebarActive]);

  const onToggleUserDrawer = useCallback(() => {
    setUserDrawerActive(!userDrawerActive);
  }, [userDrawerActive]);

  return (
    <div className={classes.container}>
      <NavBar
        onToggleSideBar={onToggleSideBar}
        onToggleUserDrawer={onToggleUserDrawer}
      />
      <UserDrawer active={userDrawerActive} onClose={onDrawerClose} />
      <main className={classes.main}>
        <SideBar active={sidebarActive} />
        <div className={classes.mainContent}>{children}</div>
      </main>
    </div>
  );
};

export default Container;
