import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  programPageActions,
  programPageSelectors,
} from "../Pages/Programs/ProgramPage/programSlice";
import { programPageApi } from "../Pages/Programs/ProgramPage/programPageApi";
import { message } from "antd";

const useProgramData = () => {
  const dispatch = useDispatch();
  const { programId } = useParams();

  const programIsFetching = useSelector(programPageSelectors.programIsFetching);
  const programHasError = useSelector(programPageSelectors.programHasError);
  const programErrorMessage = useSelector(
    programPageSelectors.programErrorMessage
  );
  const program = useSelector(programPageSelectors.program);

  useEffect(() => {
    dispatch(programPageApi.getProgramData(programId));

    return () => dispatch(programPageActions.resetProgram());
  }, [dispatch, programId]);

  useEffect(() => {
    if (programHasError) {
      message.error(`Error fetching program data: ${programErrorMessage}`);
    }
  }, [dispatch, programErrorMessage, programHasError]);

  return {
    program,
    programIsFetching,
  };
};

export default useProgramData;
