import React, { useEffect } from "react";
import { Button, Form, Input, message } from "antd";
import AuthContainer from "../AuthContainer";
import classes from "../Auth.module.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginActions, loginSelectors } from "./loginSlice";
import Alert from "antd/es/alert";
import { authApi } from "../authApi";
// import { classNames } from "../../../util/functions";
import { useHistory } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    dispatch(authApi.login(values));
  };

  const success = useSelector(loginSelectors.success);
  const failure = useSelector(loginSelectors.failure);
  const loading = useSelector(loginSelectors.loading);
  const errorMessage = useSelector(loginSelectors.errorMessage);

  const history = useHistory();

  useEffect(() => {
    if (success) {
      message.success("Successfully logged in. Redirecting");
      history.push("/programs");
      dispatch(loginActions.reset());
    }
  }, [success, failure, history, dispatch]);

  return (
    <AuthContainer
      header={"Welcome back"}
      subtext={"Sign in to continue"}
      formHeader={"Sign In"}
    >
      {failure && (
        <Alert
          message={"Something went wrong"}
          description={errorMessage}
          type={"error"}
          className={classes.status}
          closable
        />
      )}
      {success && (
        <Alert
          message={"Successfully logged in. Redirecting"}
          type={"success"}
          className={classes.status}
        />
      )}
      <Form
        className={classes.formInputs}
        initialValues={{
          email: "",
          password: "",
        }}
        onFinish={onSubmit}
      >
        <Form.Item
          name={"email"}
          rules={[
            {
              required: true,
              message: "Email address is required",
            },
            {
              type: "email",
              message: "Please input a valid email",
            },
          ]}
        >
          <Input
            placeholder={"Email address"}
            aria-placeholder={"Email address"}
          />
        </Form.Item>
        <Form.Item
          name={"password"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.Password
            placeholder={"Password"}
            aria-placeholder={"Password"}
          />
        </Form.Item>
        <Link to={"/auth/forgot-password"} className={classes.formLink}>
          Forgot your password?
        </Link>
        <Button type={"primary"} block htmlType={"submit"} loading={loading}>
          Sign In
        </Button>
      </Form>
    </AuthContainer>
  );
};

export default Login;
